import React, { useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import { inPlaceSort } from 'fast-sort';

const ProductionPhasesScreen = ({ data, columns, rows }) => {
    const [filteredData, setFilteredData] = useState(data);
    const [selectedItem, setSelectedItem] = useState(null);

    inPlaceSort(rows).by("index");
    
    return (
        <div className='gridtable-for-production-phase'>
            <GridTable
                columns={columns}
                rows={rows}
                canReorder={false}
                enableColumnsReorder={false}
                showSearch={true}
                isPaginated={false}
                showColumnVisibilityManager={false}
                showRowsInformation={false}
                texts={{ noResults: 'Nenhum resultado foi encontrado', search: 'Buscar:' }}
            />
        </div>
    )
}

export default ProductionPhasesScreen