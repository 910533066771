import React from 'react';
import { openRoutes } from './routes';
import { Route, Switch, Redirect } from "react-router-dom";
import LoginContainer from '../../containers/Login/LoginContainer';

export const PublicRoutes = () => {
    return <Switch>
        {openRoutes.map(({ path, Component }) => <Route exact={true} key={path} path={path} component={Component} />)}
        <Redirect to="/login" />
    </Switch>
}