import React, { useState, useRef } from "react";
import ModalServiceTypeContainer from "../../containers/ModalServiceType/ModalServiceTypeContainer";
import ModalProductionPhaseContainer from "../../containers/ModalProductionPhase/ModalProductionPhaseContainer";
import ModalValueHourChartContainer from "../../containers/ModalValueHourChart/ModalValueHourChartContainer";
import ModalServiceContainer from "../../containers/ModalServiceContainer/ModalServiceContainer";

const ModalBodyShop = ({ bodyShop }) => {
  return (
    <>
      {bodyShop === 1 && <ModalServiceTypeContainer />}
      {bodyShop === 2 && <ModalProductionPhaseContainer />}
      {bodyShop === 3 && <ModalValueHourChartContainer />}
      {bodyShop === 4 && <ModalServiceContainer />}
    </>
  );
};

export default ModalBodyShop;
