import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { alertError, alertSuccess } from "../../components/Toast";
import { validateEmail } from "../../core/utils/string";
import ModalCompany from "../../screens/ModalCompany/ModalCompany";
import { supabase } from "../../supabaseClient";
import { setData } from "../../core/redux/app/Actions";
import { swalInput, swalRemove } from "../../components/Swal/Swal";
import { compareValues } from "../ModalClients/ModalClientsContainer";
import Swal from "sweetalert2";

const ModalCompanyContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const params = useParams();
  const isNew = params.id === "0";
  const [values, setValues] = useState({ active: true });
  const [oldValues, setOldValues] = useState({ active: true });
  const [companyList, setCompanyList] = useState({});
  const [users, setUsers] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const reloadCompany = useSelector(
    (state) => state.app.reloadCompany || false
  );
  const loggedUser = useSelector((state) => state.app.user || {});

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    closeModal();
  };

  const loadData = async () => {
    let { data, error } = await supabase
      .from("Company")
      .select("*")
      .eq("id", id)
      .is("deleted_at", null);

    if (!error) {
      setValues(data[0]);
      setOldValues(data[0]);
    }
  };

  const loadCompanyList = async () => {
    let { data, error } = await supabase
      .from("Company")
      .select("*")
      .is("deleted_at", null);

    if (!error) {
      setCompanyList(
        data.map((item) => ({ label: item.name, value: item.id }))
      );
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id !== "0") {
      loadData();
      loadUsers();
    }
  }, [id]);

  useEffect(() => {
    loadCompanyList();
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [values]);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    dispatch(
      setData({ [`compareValues_${path}`]: compareValues(oldValues, values) })
    );
  }, [values, oldValues]);

  useEffect(() => {
    loadUsers();
  }, [reloadUsers]);

  const loadUsers = async () => {
    const { data, error } = await supabase
      .from("UserCompany")
      .select(
        `
                type,
                user (
                    *
                )`
      )
      .eq("company", params.id)
      .is("deleted_at", null);

    if (!error) {
      const newData = data.map(({ user, type }) => ({ ...user, type }));
      setUsers(newData);
    }
  };

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados serão descartados.",
        denyButtonText: `Descartar`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/company");
        }
      });
    } else {
      history.push("/company");
    }
  };

  const addUser = async (email) => {
    if (email && email !== "") {
      if (validateEmail(email)) {
        if (!users.find((item) => item.email === email)) {
          const name = email.split("@")[0];
          const user = { name, email, isRegistered: false };
          if (isNew) {
            setUsers((users) => users.concat(user));
          } else {
            let newUser = await addDBUser(email, user);

            if (newUser && newUser.id) {
              const { data, error } = await supabase
                .from("UserCompany")
                .upsert([{ user: newUser.id, company: params.id, type: 3 }])
                .select(`*`);

              if (newUser && !newUser.isRegistered) {
                supabase.auth.api.inviteUserByEmail(newUser.email);
              }
              alertSuccess("Sucesso ao salvar o funcionário!");
              setUsers((users) => users.concat(newUser));
            } else {
              alertError(
                "Erro ao salvar o funcionário, tente novamente mais tarde."
              );
            }
          }
        } else {
          alertError("E-mail já existe nessa empresa");
        }
      } else {
        alertError("E-mail é inválido");
      }
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const validateCnpj = (cnpj) => {
    const regex =
      /^(?:(?:\d{3}\.){2}\d{3}-\d{2}|\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/;
    return regex.test(cnpj);
  };

  const copyUsers = async (sourceCompanyId, targetCompanyId) => {
    const { data, error } = await supabase
      .from("Client")
      .select("*")
      .eq("id_company", sourceCompanyId)
      .is("deleted_at", null);

    if (!error) {
      const usersToCopy = data.map(({ id, ...rest }) => ({
        ...rest,
        id_company: targetCompanyId,
      }));

      const { data: newData, error: insertError } = await supabase
        .from("Client")
        .insert(usersToCopy);

      if (insertError) {
        console.error("Erro ao copiar usuários:", insertError);
      }
    } else {
      console.error("Erro ao carregar usuários:", error);
    }
  };

  const copyConfig = async (sourceCompanyId, targetCompanyId) => {
    const newParts = await supabase
      .from("Part")
      .select("*")
      .eq("company", sourceCompanyId)
      .is("deleted_at", null);

    if (!newParts.error) {
      const dataToCopy = newParts.data.map(({ id, ...rest }) => ({
        ...rest,
        company: targetCompanyId,
      }));
      const { data: newData, error: insertError } = await supabase
        .from("Part")
        .insert(dataToCopy);
      if (insertError) {
        console.error(`Erro ao copiar dados da tabela Part:`, insertError);
      }
    } else {
      console.error(`Erro ao carregar dados da tabela Part:`, newParts.error);
    }

    const newServiceTypes = await supabase
      .from("ServiceTypes")
      .select("*")
      .eq("id_company", sourceCompanyId)
      .is("deleted_at", null);
    let newServiceTypesData = [];
    if (!newServiceTypes.error) {
      const dataToCopy = newServiceTypes.data.map(({ id, ...rest }) => ({
        ...rest,
        id_company: targetCompanyId,
      }));
      const { data: newData, error: insertError } = await supabase
        .from("ServiceTypes")
        .insert(dataToCopy);
      newServiceTypesData = newData;
      if (insertError) {
        console.error(
          `Erro ao copiar dados da tabela ServiceTypes:`,
          insertError
        );
      }
    } else {
      console.error(
        `Erro ao carregar dados da tabela ServiceTypes:`,
        newServiceTypes.error
      );
    }

    const allServiceTypes = await supabase
      .from("ServiceTypes")
      .select("id, title")
      .eq("id_company", sourceCompanyId)
      .is("deleted_at", null);

    const newServices = await supabase
      .from("Services")
      .select("*")
      .eq("id_company", sourceCompanyId)
      .is("deleted_at", null);

    if (!newServices.error) {
      const dataToCopy = newServices.data.map(
        ({ id, service_type, ...rest }) => {
          const oldService = allServiceTypes.data.find(
            (type) => type.id === service_type
          );
          var matchingServiceType = null;
          if (oldService) {
            matchingServiceType = newServiceTypesData.find(
              (item) => item.title === oldService.title
            );
          } else {
            matchingServiceType = newServiceTypesData.find((item) =>
              item.title.includes(extrairTextoEntreParenteses(rest.title))
            );
          }
          return {
            ...rest,
            id_company: targetCompanyId,
            service_type: matchingServiceType ? matchingServiceType.id : null,
          };
        }
      );

      const { data: newData, error: insertError } = await supabase
        .from("Services")
        .insert(dataToCopy);

      if (insertError) {
        console.error(`Erro ao copiar dados da tabela Services:`, insertError);
      }
    } else {
      console.error(
        `Erro ao carregar dados da tabela Services:`,
        newServices.error
      );
    }

    const newValueHour = await supabase
      .from("HourlyValue")
      .select("*")
      .eq("id_company", sourceCompanyId)
      .is("deleted_at", null);

    if (!newValueHour.error) {
      const dataToCopy = newValueHour.data.map(({ id, ...rest }) => {
        return {
          ...rest,
          id_company: targetCompanyId,
          service_types: JSON.stringify(
            newServiceTypesData.map((item, index) => {
              const extraData = {};
              const value = JSON.parse(rest.service_types).find(
                (row, i) => i === index
              )?.value;
              if (value) {
                extraData["value"] = value;
              }
              return {
                ...item,
                ...extraData,
              };
            })
          ),
        };
      });

      const { data: newData, error: insertError } = await supabase
        .from("HourlyValue")
        .insert(dataToCopy);

      if (insertError) {
        console.error(
          `Erro ao copiar dados da tabela HourlyValue:`,
          insertError
        );
      }
    } else {
      console.error(
        `Erro ao carregar dados da tabela HourlyValue:`,
        newValueHour.error
      );
    }
  };

  const onSave = async () => {
    if (values && !values.name) {
      alertError("Nome é obrigatório");
      return;
    }

    if (
      (values && !values.cellphone) ||
      (values && values.cellphone && values.cellphone[14] === "_")
    ) {
      alertError("Celular é obrigatório");
      return;
    }

    if (values && !values.cnpj) {
      alertError("CPF ou CNPJ é obrigatório");
      return;
    }

    // Chama a função de validação de CPF e retorna mensagem de erro caso não esteja dentro das condições estabelecidas.
    if (!validateCnpj(values.cnpj)) {
      alertError("CPF ou CNPJ inválido");
      return;
    }

    if (values && !values.cep) {
      alertError("CEP é obrigatório");
      return;
    }

    if (values && !values.state) {
      alertError("Estado é obrigatório");
      return;
    }

    if (values && !values.city) {
      alertError("Cidade é obrigatória"); // Na presença do CEP, automaticamente confirma-se a presença de outros dados (UF e cidade)
      return;
    }

    if (values && !values.street) {
      alertError("Rua é obrigatória"); // Na presença do CEP, automaticamente confirma-se a presença de outros dados (UF e cidade)
      return;
    }

    if (values && !values.neighborhood) {
      alertError("Bairro é obrigatório"); // Na presença do CEP, automaticamente confirma-se a presença de outros dados (UF e cidade)
      return;
    }

    const { data, error } = await supabase.from("Company").upsert([values]);

    if (!error) {
      const company = data[0];
      const companyId = company.id;
      // dispatch(setData({ company }));

      const selectedCompanyId = values.selectedCompany
        ? values.selectedCompany.value
        : null;

      if (selectedCompanyId) {
        if (values.copyUsers) {
          await copyUsers(selectedCompanyId, companyId);
        }
        if (values.copyConfig) {
          await copyConfig(selectedCompanyId, companyId);
        }
      }

      alertSuccess("Sucesso ao salvar a empresa!");
      if (
        users.filter((item) => !item.id) &&
        users.filter((item) => !item.id).length > 0
      ) {
        saveUsers(companyId);
      } else {
        history.push("/company");
        dispatch(setData({ reloadCompany: !reloadCompany }));
      }
    } else {
      alertError("Erro ao salvar a empresa, tente novamente mais tarde.");
    }
  };

  const saveUsers = async (company) => {
    const usersToSave = users.filter((item) => !item.id);
    let testUsers = [];

    for (var i = 0; i < usersToSave.length; i++) {
      const user = usersToSave[i];
      let newUser = await addDBUser(`${user.email}`.trim().toLowerCase(), user);

      if (newUser && newUser.id) {
        const { data, error } = await supabase
          .from("UserCompany")
          .upsert([{ user: newUser.id, company, type: 3 }])
          .select(`*`);

        if (newUser && !newUser.isRegistered) {
          supabase.auth.api.inviteUserByEmail(newUser.email);
        }

        testUsers.push(newUser);
      }
    }

    if (testUsers && testUsers.length > 0) {
      alertSuccess("Sucesso ao salvar os funcionários!");
      history.push("/company");
      dispatch(setData({ reloadCompany: !reloadCompany }));
    } else {
      alertError("Erro ao salvar os funcionários, tente novamente mais tarde.");
    }
  };

  const onRemoveUser = async (user) => {
    if (user && user.id) {
      const { data, error } = await supabase
        .from("UserCompany")
        .update({ deleted_at: new Date(), deleted_by: loggedUser?.id || null })
        .eq("user", user.id)
        .eq("company", params.id);

      if (!error) {
        alertSuccess("Funcionário removido com sucesso!");
        setUsers((users) => users.filter((row) => row.id !== user.id));
      } else {
        alertError("Erro ao remover o funcionário, tente novamente mais tarde");
      }
    } else {
      alertError("Erro ao remover o funcionário, tente novamente mais tarde");
    }
  };

  const onEditPassword = async (user) => {
    const response = await swalInput();
    try {
      if (response?.isConfirmed || response?.value) {
        const password = response.value;
        if (!new RegExp(`^.{${6},}$`).test(password)) {
          alertError("A senha precisa ter pelo menos 6 dígitos");
          return;
        }

        const { data: users, error: errorUsers } =
          await supabase.auth.api.listUsers();
        if (errorUsers) throw errorUsers;

        const authUser = users.find((auth) => auth.email === user.email);
        const { data, error: errorUpdateUsers } =
          await supabase.auth.api.updateUserById(authUser.id, {
            password,
            email_confirm: true,
          });

        if (errorUpdateUsers) throw errorUpdateUsers;
        alertSuccess("Senha cadastrada com sucesso!");

        // const { data: updateData, error: updateError } = await supabase
        //   .from("User")
        //   .update({ isRegistered: true })
        //   .eq("id", user.id);
        // console.log({ updateError });
        // if (updateError) throw updateError;
      }
    } catch (error) {
      console.error("Erro ao atualizar senha:", error);
      alertError("Erro ao atualizar a senha. Tente novamente.");
    }
  };

  const onChangeType = async (item) => {
    if (item && item.type !== 0) {
      const { data, error } = await supabase
        .from("UserCompany")
        .update({ type: item.type - 1 > 0 ? item.type - 1 : 3 })
        .eq("user", item.id)
        .eq("company", params.id);
      if (!error) {
        setReloadUsers((reload) => !reload);
        alertSuccess("Tipo do funcionário alterado com sucesso!");
      } else {
        alertError(
          "Erro ao alterar o tipo do funcionário, tente novamente mais tarde"
        );
      }
    }
  };

  const onSendEmail = async (user) => {
    if (user && user.email && !user.isRegistered) {
      let { data, error } = await supabase.auth.api.inviteUserByEmail(
        user.email
      );

      if (!error) {
        alertSuccess("E-mail reenviado com sucesso!");
      } else {
        if (error.status === 422) {
          let { data, error } = await supabase.auth.signIn(
            { email: user.email },
            { shouldCreateUser: false }
          );

          if (!error) {
            alertSuccess("E-mail reenviado com sucesso!");
          } else {
            alertError(error.message);
          }
        }
      }
    }
  };

  const changeCEP = (cep) => {
    onChange("cep", cep);
    if (cep && cep.length >= 9 && /^[0-9]{5}-?[0-9]{3}$/.test(cep)) {
      fetch(`https://viacep.com.br/ws/${cep.replace("-", "")}/json/`).then(
        (response) => {
          response.json().then((data) => {
            const { uf, logradouro, localidade, bairro } = data;
            setValues((values) => ({
              ...values,
              cep,
              state: uf,
              // street: logradouro,
              city: localidade,
              // neighborhood: bairro,
            }));
          });
        }
      );
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: "Deseja mesmo excluir?",
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: "warning",
      text: "Seus dados serão completamente perdidos!",
      denyButtonText: "Excluir",
      cancelButtonText: "Cancelar",
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from("Company")
          .update({
            deleted_at: new Date(),
            deleted_by: loggedUser?.id,
            cnpj: null,
          })
          .eq("id", values.id);

        if (!error) {
          alertSuccess("Empresa removida com sucesso!");
          history.push("/company");
          dispatch(setData({ reloadCompany: !reloadCompany }));
        } else {
          alertError("Erro ao remover a empresa, tente novamente mais tarde!");
        }
      } else {
        alertError("Erro ao remover a empresa, tente novamente mais tarde!");
      }
    } else {
      history.push("/company");
    }
  };

  return (
    <ModalCompany
      companyList={companyList}
      compareValues={compareValues(oldValues, values)}
      closeModal={closeModal}
      addUser={addUser}
      onChange={onChange}
      onSave={onSave}
      values={values}
      isNew={isNew}
      users={users}
      onChangeType={onChangeType}
      onRemove={onRemove}
      onRemoveUser={(item) =>
        swalRemove({
          withError: false,
          functionConfirmRemove: () => onRemoveUser(item),
        })
      }
      onEditPassword={onEditPassword}
      onSendEmail={onSendEmail}
      changeCEP={changeCEP}
      loggedUser={loggedUser}
    />
  );
};

export default ModalCompanyContainer;

const addDBUser = async (email, user) => {
  let newUser = null;
  const { data: dataSelect, error: errorSelect } = await supabase
    .from("User")
    .select(`*`)
    .eq("email", email);

  if (!errorSelect) {
    if (dataSelect && dataSelect.length === 0) {
      const { data, error } = await supabase
        .from("User")
        .upsert([user])
        .select(`*`);

      if (!error) {
        newUser = data[0];
      }
    } else {
      newUser = dataSelect[0];
    }
  }

  return newUser;
};

function extrairTextoEntreParenteses(texto) {
  const regex = /\(([^)]+)\)/;
  const resultado = regex.exec(texto);
  return resultado ? resultado[1] : null;
}
