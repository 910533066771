import React, { useEffect, useState } from 'react';
import { useSidebarControls } from '../../core/hooks/sidebar';
import { HiOutlineFilter } from 'react-icons/hi';

const Menu = ({ children, manualOpen, className }) => {
    const { collapsed } = useSidebarControls();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(manualOpen);
    }, [manualOpen])

    return <div className={`${className} prospera-menu-container ${!collapsed && "isOpen"}`}>
        {open && <div className={`prospera-menu ${open ? "open" : "closed"}`}>
            {children}
        </div>}
        <div onClick={() => setOpen(!open)} className={`prospera-menu-button ${open ? "open" : "closed"}`}>
            <HiOutlineFilter className={`icon`} />
        </div>
    </div>
}

export const RightMenu = (props) => {
    return <Menu {...props} className="right" />
}

export const LeftMenu = (props) => {
    return <Menu {...props} className="left" />
}