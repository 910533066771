import React, { useState, useEffect, useMemo } from "react";
("pt-br");
import { BiWindow, BiWindows } from "react-icons/bi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "@mui/x-date-pickers/locales";
import {
  LabelCreatable,
  DayInput,
  LabelInput,
  MoneyInput,
  HourInput,
  PercentageInput,
  SelectInput,
} from "../../components/Input/Input";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSend,
  AiOutlinePrinter,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { BsFillTrashFill, BsTrashFill } from "react-icons/bs";
import "react-tabs/style/react-tabs.css";
import ButtonSwitch from "../../components/Switch";
import Selector from "../../components/Selector";
import GridTable from "@nadavshaar/react-grid-table";
import BudgetAddScreen from "../BudgetAdd/BudgetAddScreen";
import PieceAddScreen from "../PieceAdd/PieceAddScreen";
import BudgetAddClient from "../BudgetAddClient/BudgetAddClient";
import BudgetAddVehicle from "../BudgetAddVehicle/BudgetAddVehicle";
import { isMobile } from "react-device-detect";
import BudgetPrint from "../../components/BudgetPrint/BudgetPrint";
import { useReactToPrint } from "react-to-print";
import { supabase } from "../../supabaseClient";
import { saveBudget } from "../../core/utils/budget";
import PieceEdit from "../../components/PieceEdit/PieceEdit";
import { FaCheck, FaMagnifyingGlass, FaQuestion } from "react-icons/fa6";
import { FaPercentage } from "react-icons/fa";
import Swal from "sweetalert2";
import { inPlaceSort } from "fast-sort";
import { FaTrashCan } from "react-icons/fa6";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import { Spinner } from "reactstrap";
import { alertError } from "../../components/Toast";

const ModalBudget = ({
  values,
  oldValues,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
  budget,
  showBudgetItems,
  open,
  setOpen,
  addBudget,
  client,
  vehicle,
  service,
  setService,
  part,
  setPart,
  parts,
  setParts,
  onRemovePiece,
  onRemoveService,
  setReload,
  hvTable,
  setAddBudget,
  sendWhatsapp,
  setIsntLinearHour,
  isntLinearHour,
  serviceType,
  setServiceType,
  addedParts,
  addedServices,
  paymentOptions,
  createOption,
  changeValue,
  changePartsValue,
  reloadPrint,
  company,
  isOpen,
  setIsOpen,
  openEditModal,
  onChangePiece,
  printCompany,
  isNewValues,
  deleteOptions,
  createOrigin,
  divCreatable,
  menuOpen,
  creatableOpen,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const totalSum = useMemo(() => getTotalSum(service, parts), [service, parts]);
  const totalService = useMemo(() => getTotalSum(service, []), [service]);
  const totalParts = useMemo(() => getTotalSum([], parts), [parts]);
  const [isLoading, setIsLoading] = useState(false);

  const ref = React.useRef();

  const originalTitle = document.title;

  const handlePrint = useReactToPrint({
    content: () => ref.current,

    onBeforeGetContent: () => {
      const newTitle = `Orcamento_${values.code}_${values.client_name}_${values.vehicle_name}}`;
      document.title = newTitle;
      return Promise.resolve();
    },

    onAfterPrint: () => {
      document.title = originalTitle;
    },

    onBeforePrint: async () => {
      const budgetId = values.id;
      if (values?.shared_at === null) {
        await saveBudget(budgetId, {
          shared_at: new Date(),
        });
      }
    },
  });

  const nodeInBlob = async () => {
    return new Promise((resolve) => {
      // const printWindow = window.open("", "_blank");
      // printWindow.document.write(ref.current.outerHTML);
      // printWindow.focus();
      setTimeout(() => {
        // const blob = new Blob([ref.current.outerHTML], {
        //   type: "application/pdf",
        // });
        var node = document.getElementById("my-node");
        domtoimage.toPng(node).then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          resolve(img);
        });
      }, 1000);
    });
  };

  const generatePdfBlob = async () => {
    const image = await nodeInBlob();

    return new Promise((resolve) => {
      const pdf = new jsPDF({ margin: [0, 0, 0, 0] });
      pdf.addImage(image, "PNG", 0, 0);
      const pdfBlob = pdf.output("blob");
      resolve(pdfBlob);
    });
  };

  const convertImageToBlob = (image) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      ctx.drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  const toShare = async () => {
    try {
      setIsLoading(true);
      const sanitizedClientName = values.client_name.replace(/[\s\\/]/g, "_");
      const sanitizedVehicleName = values.vehicle_name.replace(/[\s\\/]/g, "_");
      const newTitle = `Orcamento_${values.code}_${sanitizedClientName}_${sanitizedVehicleName}.pdf`;

      const pdfBlob = await generatePdfBlob();

      // const imageBlob = await convertImageToBlob(image);

      const { data, error } = await supabase.storage
        .from("budget")
        .upload(newTitle, pdfBlob, {
          contentType: "application/pdf",
          upsert: true,
        });

      if (error) {
        console.error("Erro ao enviar o PDF ao Supabase:", error);
        return;
      } else {
        console.log("PDF enviado com sucesso.", data.Key);
      }

      const { publicURL, error: urlError } = supabase.storage
        .from("budget")
        .getPublicUrl(newTitle);

      if (urlError) {
        console.error("Erro ao obter a URL pública do PDF:", urlError);
        return;
      }

      const shareData = {
        title: newTitle,
        text: "Aqui está o orçamento que você solicitou.",
        files: [
          new File([pdfBlob], `${newTitle}`, { type: "application/pdf" }),
        ],
      };

      if ("canShare" in navigator) {
        // await Swal.fire({
        //   title: "Você deseja compartilhar em PDF ou em PNG?",
        //   showConfirmButton: true,
        //   showDenyButton: true,
        //   icon: "warning",
        //   confirmButtonText: `PDF`,
        //   denyButtonText: "PNG",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     navigator.share(shareData);
        //   } else if (result.isDenied) {
        //     navigator.share({
        //       title: `Orçamento ${values.code}`,
        //       files: [
        //         new File([imageBlob], `Orcamento_${values.code}.png`, {
        //           type: "image/png",
        //         }),
        //       ],
        //     });
        //   }
        // });
        await navigator.share(shareData);
        console.log("Orçamento compartilhado com sucesso.");
      } else {
        console.error(
          "O módulo de compartilhar não é suportado neste navegador, tente outro."
        );
      }
    } catch (error) {
      console.error("Erro ao compartilhar:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortedPaymentOptions = paymentOptions.map((item) => ({
    value: item.payment_type.value,
    label: item.payment_type.label,
    id: item.id,
  }));

  inPlaceSort(sortedPaymentOptions).asc([
    (item) => item.label.replace(/[^a-zA-Z]/g, "").toLowerCase(),
    (item) => {
      const numberMatch = item.label.match(/\d+/);
      return numberMatch ? parseInt(numberMatch[0], 10) : 0;
    },
  ]);

  const toPrint = () =>
    !compareValues
      ? handlePrint()
      : Swal.fire({
          title: "Salve o conteúdo para poder imprimir o PDF.",
          showCancelButton: true,
          showConfirmButton: true,
          showDenyButton: false,
          icon: "warning",
          confirmButtonText: `Salvar`,
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            onSave(handlePrint);
          } else {
            history.push("/budgets");
          }
        });

  useEffect(() => {
    const prosperaModal = document.querySelector(".prospera-modal");
    const modalContent = document.querySelector(".modal-content");
    const mainModal = document.querySelector(".main-content-modal1");
    const modalWrapper = document.querySelector(".modal-content-wrapper");
    const textArea = document.querySelector(".text-observation-min");
    const textArea1 = document.querySelector(".text-observation-min2");
    const serviceAdditionalArea = document.querySelector(
      ".service-additional-on"
    );
    const pieceGridtable = document.querySelector(".piece-gridtable");
    const serviceGridtable = document.querySelector(".service-gridtable");
    if (modalContent) {
      modalContent.style.backgroundColor = isFullscreen ? "#4b4b4d" : "#fff";
    }

    if (prosperaModal) {
      prosperaModal.classList.toggle("expanded", !isFullscreen);
      prosperaModal.classList.toggle("prospera-modal-fullscreen", isFullscreen);

      mainModal.classList.toggle("main-content-modal2", isFullscreen);
      modalWrapper.classList.toggle("modal-content-wrapper2", isFullscreen);
    }
    if (serviceAdditionalArea) {
      serviceAdditionalArea.classList.toggle(
        "service-additional-off",
        isFullscreen
      );
    }
    if (pieceGridtable) {
      pieceGridtable.classList.toggle("fullscreen-gridtable", isFullscreen);
    }
    if (serviceGridtable) {
      serviceGridtable.classList.toggle("fullscreen-gridtable", isFullscreen);
    }
  }, [isFullscreen]);

  inPlaceSort(service).by("title");
  inPlaceSort(parts).by("part");

  const OptionFunc = (props, item) => {
    const { data } = props;
    return (
      <div
        {...props}
        className="deletable-creatable"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "40px",
          padding: "10px",
        }}
      >
        <div
          onClick={(e) => divCreatable(data, e)}
          style={{
            textTransform: "uppercase",
            width: "100%",
            height: "40px",
            alignContent: "center",
          }}
        >
          {data?.label}
        </div>
        <div>
          <FaTrashCan
            style={{ color: "rgb(255 130 120)" }}
            onClick={(e) => deleteOptions(data.id, e)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={""}>
        {/* {`prospera-modal ${fullscreen ? 'prospera-modal-fullscreen' : 'prospera-modal-fullscreen'}`} */}
        <div className={"modal-container"}>
          <div className="modal-header">
            <div className="header-title">
              <h4 className="modal-title">
                {isNew ? "Adicionar Orçamento" : "Editar Orçamento"}
              </h4>
            </div>
            <div className="header-buttons">
              {compareValues ? (
                <DefaultButton
                  className="modal-footer-button"
                  label={isMobile ? "" : "Salvar"}
                  icon={<AiOutlineCheck className="button-icon" />}
                  onClick={onSave}
                />
              ) : (
                <div />
              )}
              {isMobile ? (
                <div />
              ) : (
                <DefaultButton
                  className="modal-footer-button cancel"
                  onClick={() => {
                    setIsFullscreen(!isFullscreen);
                    showBudgetItems(1);
                  }}
                  icon={!isFullscreen ? <BiWindow /> : <BiWindows />}
                />
              )}
              <DefaultButton
                className="modal-footer-button cancel"
                label={isMobile ? "" : "Cancelar"}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
          </div>
          <div className="modal-content-wrapper">
            <div className="main-content-modal1">
              <div style={{ textAlign: "center" }}>
                {isFullscreen ? (
                  budget === 1 ? (
                    <div style={{ paddingTop: "10px" }}>
                      <Selector
                        handleClick={() => showBudgetItems(1)}
                        buttonClass={budget === 1 ? "highlighted" : ""}
                        id={"Detalhes"}
                        name={"Detalhes"}
                      />
                      <Selector
                        handleClick={() => showBudgetItems(4)}
                        buttonClass={budget === 4 ? "highlighted" : ""}
                        id={"Tabelas"}
                        name={"Tabelas"}
                      />
                    </div>
                  ) : null
                ) : (
                  <div>
                    <Selector
                      handleClick={() => showBudgetItems(1)}
                      buttonClass={budget === 1 ? "highlighted" : ""}
                      id={"Detalhes"}
                      name={"Detalhes"}
                    />
                    <Selector
                      handleClick={() => showBudgetItems(2)}
                      buttonClass={budget === 2 ? "highlighted" : ""}
                      id={"Serviços"}
                      name={"Serviços"}
                    />
                    <Selector
                      handleClick={() => showBudgetItems(3)}
                      buttonClass={budget === 3 ? "highlighted" : ""}
                      id={"Peças"}
                      name={"Peças"}
                    />
                  </div>
                )}
              </div>

              {isFullscreen ? <></> : <></>}

              {budget === 1 && (
                <div className="budget-details-inputs">
                  <div className="budget-modal-main-div">
                    <div className="size-dropdown">
                      <div className="void-class">
                        <SelectInput
                          onChange={(event) => onChange("client", event)}
                          placeholder={"Escolha o Cliente"}
                          label={"Cliente"}
                          // isSearchable={!isMobile}
                          options={client}
                          defaultValue={
                            client?.find(
                              (item) =>
                                item.value === values?.client?.value ||
                                item.value === values?.client
                            ) &&
                            client?.find(
                              (item) =>
                                item.value === values?.client?.value ||
                                item.value === values?.client
                            )?.value
                              ? client.find(
                                  (item) =>
                                    item.value === values?.client?.value ||
                                    item.value === values?.client
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                    <DefaultButton
                      className="add-button-budget"
                      label={"+"}
                      onClick={() => setOpen(3)}
                    />
                  </div>
                  <div className="budget-modal-main-div">
                    <div className="size-dropdown">
                      <div className="void-class">
                        <SelectInput
                          // isSearchable={!isMobile}
                          onChange={(event) => onChange("vehicle", event)}
                          placeholder={"Escolha o Veículo"}
                          label={"Veículo"}
                          options={vehicle}
                          // disabled={!values.client}
                          defaultValue={
                            vehicle?.find(
                              (item) =>
                                item.value === values?.vehicle?.value ||
                                item.value === values?.vehicle
                            ) &&
                            vehicle?.find(
                              (item) =>
                                item.value === values?.vehicle?.value ||
                                item.value === values?.vehicle
                            )?.value
                              ? vehicle?.find(
                                  (item) =>
                                    item.value === values?.vehicle?.value ||
                                    item.value === values?.vehicle
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                    <DefaultButton
                      className="add-button-budget"
                      label={"+"}
                      onClick={() => setOpen(4)}
                    />
                  </div>
                  <div className="payment-dropdown">
                    <div
                      onClick={(e) => creatableOpen(e)}
                      className="void-class"
                    >
                      <LabelCreatable
                        menuIsOpen={menuOpen}
                        onCreateOption={(name) => createOption(name, "payment")}
                        components={{ Option: (props) => OptionFunc(props) }}
                        options={inPlaceSort(sortedPaymentOptions).asc([
                          (item) =>
                            item.label.replace(/[^a-zA-Z]/g, "").toLowerCase(),
                          (item) => {
                            const numberMatch = item.label.match(/\d+/);
                            return numberMatch
                              ? parseInt(numberMatch[0], 10)
                              : 0;
                          },
                        ])}
                        defaultValue={values?.payment}
                        onChange={(value) => onChange("payment", value)}
                        placeholder={"INSIRA A FORMA DE PAGAMENTO"}
                        label={"Forma de pagamento"}
                        className="generic-input select-creatable budget-percentage-input budget-creatable"
                        styles={{
                          option: (base) => ({
                            ...base,
                            border: ``,
                            height: "100%",
                          }),
                        }}
                      />
                    </div>
                  </div>

                  <div className="expiration-date">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                      location={ptBR}
                      localeText={
                        ptBR.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                    >
                      <DayInput
                        value={values?.expiration}
                        onChange={(value) => onChange("expiration", value)}
                        placeholder={"Dias"}
                        label={"Prazo de Validade"}
                        className="validity-input budget-percentage-input"
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                      locale={ptBR}
                      localeText={
                        ptBR.components.MuiLocalizationProvider.defaultProps
                          .localeText
                      }
                    >
                      <DayInput
                        className="validity-input budget-percentage-input"
                        value={values?.deadline}
                        onChange={(value) => onChange("deadline", value)}
                        placeholder={"Dias"}
                        label={"Prazo de Entrega"}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="void-class">
                    <SelectInput
                      onChange={(value) => onChange("status", value)}
                      placeholder={"Escolha o status do orçamento"}
                      // isSearchable={!isMobile}
                      label={"Status"}
                      defaultValue={
                        statusOptions.find(
                          (item) => item.id === values?.status
                        ) || values?.status
                      }
                      value={statusOptions.find(
                        (item) => item.id === values?.status
                      )}
                      options={statusOptions}
                      className="budget-percentage-input"
                    />
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <LabelInput
                      type={"textarea"}
                      inputClassname={"text-observation-min"}
                      label={"Observações"}
                      placeholder={"Insira a observação para o orçamento"}
                      onChange={(value) => onChange("observation", value)}
                      value={
                        values?.observation
                          ? values?.observation
                          : company.budgetNote
                      }
                      className="budget-percentage-input"
                    />
                  </div>
                  <div style={{ paddingBottom: "10px" }}>
                    <LabelInput
                      type={"textarea"}
                      inputClassname={"text-observation-min2"}
                      label={"Anotações"}
                      placeholder={"Insira suas anotações"}
                      onChange={(value) => onChange("annotation", value)}
                      value={values?.annotation}
                      className="budget-percentage-input"
                    />
                  </div>
                  {isFullscreen ? (
                    <div className="values-box">
                      <h4 className="values-title">
                        Total peças:{" "}
                        <span className="values-span">
                          {(totalParts ?? 0).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </h4>
                      <h4 className="values-title">
                        Total serviços:{" "}
                        <span className="values-span">
                          {(totalService ?? 0).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </h4>
                      <h4 className="values-title">
                        Total orçamento:{" "}
                        <span className="values-span">
                          {totalSum.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </h4>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
              {budget === 2 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {isMobile ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingTop: "15px",
                      }}
                    >
                      <div
                        className="size-dropdown mobile-services-input"
                        style={{ marginBottom: 5 }}
                      >
                        <div className="void-class">
                          <SelectInput
                            isSearchable={!isMobile}
                            options={sizeOptions}
                            placeholder="Selecione"
                            defaultValue={
                              sizeOptions.find(
                                (item) => item.id === values?.size
                              ) || values?.size
                            }
                            value={sizeOptions.find(
                              (item) => item.id === values?.size
                            )}
                            onChange={(value) => onChange("size", value)}
                            className="budget-percentage-input"
                          />
                        </div>
                      </div>
                      <PercentageInput
                        placeholder={"%"}
                        type="number"
                        value={values?.percentage}
                        onChange={(value) => onChange("percentage", value)}
                        className="budget-details-margin mobile-services-input"
                      />
                      <div style={{ width: "100%" }}>
                        {isntLinearHour ? (
                          <div className="mobile-switch-div">
                            <div className="void-class">
                              <SelectInput
                                // isSearchable={!isMobile}
                                options={hvTable}
                                label={""}
                                onChange={(value) => onChange("price", value)}
                                value={hvTable.find(
                                  (item) =>
                                    item.id == values?.price ||
                                    item.id == values?.price?.id
                                )}
                                defaultValue={hvTable.find(
                                  (item) =>
                                    item.id == values?.price ||
                                    item.id == values?.price?.id
                                )}
                                placeholder={"Selecione a tabela"}
                                className="budget-percentage-input"
                              />
                            </div>
                            <div className="mobile-switch-button">
                              <ButtonSwitch
                                value={values?.hour}
                                hasLabel={false}
                                defaultValue={values?.hour}
                                onChange={(value) => {
                                  setIsntLinearHour(value);
                                  onChange("hour", value);
                                  onChange("price", 0);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <MoneyInput
                              type="number"
                              value={values?.price}
                              onChange={(value) => onChange("price", value)}
                              placeholder={"Preço"}
                              hasLabel={false}
                              className="budget-details-margin"
                            />
                            <div style={{ alignSelf: "center" }}>
                              <ButtonSwitch
                                value={values?.hour}
                                hasLabel={false}
                                defaultValue={values?.hour}
                                onChange={(value) => {
                                  setIsntLinearHour(value);
                                  onChange("hour", value);
                                  onChange("price", 0);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="budget-modal-main-div"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <div className="size-dropdown">
                        <text
                          style={{
                            color: "white",
                            fontStyle: "normal",
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          Tamanho{" "}
                        </text>
                        <div className="void-class">
                          <SelectInput
                            isSearchable={!isMobile}
                            options={sizeOptions}
                            placeholder="Selecione"
                            defaultValue={
                              sizeOptions.find(
                                (item) => item.id === values?.size
                              ) || values?.size
                            }
                            value={sizeOptions.find(
                              (item) => item.id === values?.size
                            )}
                            onChange={(value) => onChange("size", value)}
                          />
                        </div>
                      </div>
                      <PercentageInput
                        label={"Adicional s/ pintura"}
                        placeholder={"%"}
                        type="number"
                        value={values?.percentage}
                        onChange={(value) => onChange("percentage", value)}
                        className="budget-percentage-input"
                        classLabel="budget-label-input"
                      />
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 3,
                              alignItems: "center",
                              gridGap: 10,
                            }}
                          >
                            <text
                              style={{
                                color: "white",
                                textAlign: "end",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              Hora linear{" "}
                            </text>
                            <ButtonSwitch
                              value={values?.hour}
                              defaultValue={values?.hour}
                              hasLabel={false}
                              onChange={(value) => {
                                setIsntLinearHour(value);
                                onChange("hour", value);
                                onChange("price", 0);
                              }}
                            />
                            <text style={{ color: "white", fontWeight: "600" }}>
                              {" "}
                              Hora tabela{" "}
                            </text>
                          </div>
                        </div>
                        {isntLinearHour ? (
                          <div className="budget-modal-main-div">
                            <div className="void-class">
                              <SelectInput
                                options={hvTable}
                                // isSearchable={!isMobile}
                                label={""}
                                onChange={(value) => onChange("price", value)}
                                value={hvTable.find(
                                  (item) =>
                                    item.id == values?.price ||
                                    item.id == values?.price?.id
                                )}
                                defaultValue={hvTable.find(
                                  (item) =>
                                    item.id == values?.price ||
                                    item.id == values?.price?.id
                                )}
                                placeholder={"Selecione a tabela"}
                                className="budget-percentage-input"
                              />
                            </div>
                          </div>
                        ) : (
                          <MoneyInput
                            type="number"
                            value={values?.price}
                            onChange={(value) => onChange("price", value)}
                            placeholder={"Preço"}
                            hasLabel={false}
                            className="budget-percentage-input"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <text style={{ color: "white" }}> Serviços </text>
                    <DefaultButton
                      className="add-button"
                      label={"+"}
                      onClick={() => setOpen(1)}
                    />
                  </div>
                  <div className="bodyshop-body modal-budget modal-budget-gridtable service-gridtable">
                    <GridTable
                      columns={columnsService(
                        onRemoveService,
                        values,
                        changeValue
                      ).map((column, index) => ({
                        ...column,
                        style: {
                          backgroundColor:
                            index % 2 === 0
                              ? "gray !important"
                              : "white !important",
                        },
                      }))}
                      rows={service}
                      enableColumnsReorder={false}
                      showSearch={false}
                      canReorder={false}
                      isPaginated={false}
                      showColumnVisibilityManager={false}
                      showRowsInformation={false}
                      texts={{ noResults: "Nenhum resultado foi encontrado" }}
                    />
                  </div>
                  <div className="values-box">
                    <h4 className="values-title">
                      Total peças:{" "}
                      <span className="values-span">
                        {(totalParts ?? 0).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                    <h4 className="values-title">
                      Total serviços:{" "}
                      <span className="values-span">
                        {(totalService ?? 0).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                    <h4 className="values-title">
                      Total orçamento:{" "}
                      <span className="values-span">
                        {totalSum.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                  </div>
                </div>
              )}
              {budget === 3 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    <text style={{ color: "white" }}> Peças </text>
                    <DefaultButton
                      className="add-button"
                      label={"+"}
                      onClick={() => setOpen(2)}
                    />
                  </div>
                  <div className="bodyshop-body modal-budget modal-budget-gridtable piece-gridtable">
                    <GridTable
                      columns={columnsPiece(
                        onRemovePiece,
                        changePartsValue,
                        isOpen,
                        setOpen,
                        openEditModal
                      )}
                      rows={parts}
                      enableColumnsReorder={false}
                      showSearch={false}
                      canReorder={false}
                      isPaginated={false}
                      showColumnVisibilityManager={false}
                      showRowsInformation={false}
                      texts={{ noResults: "Nenhum resultado foi encontrado" }}
                    />
                  </div>
                  <div className="values-box">
                    <h4 className="values-title">
                      Total peças:{" "}
                      <span className="values-span">
                        {(totalParts ?? 0).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                    <h4 className="values-title">
                      Total serviços:{" "}
                      <span className="values-span">
                        {(totalService ?? 0).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                    <h4 className="values-title">
                      Total orçamento:{" "}
                      <span className="values-span">
                        {totalSum.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                  </div>
                </div>
              )}
              {budget === 4 && (
                <div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div style={{ minWidth: "49%" }}>
                      <div
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Selector
                          handleClick={() => showBudgetItems(1)}
                          buttonClass={budget === 1 ? "highlighted" : ""}
                          id={"Detalhes"}
                          name={"Detalhes"}
                        />
                        <Selector
                          handleClick={() => showBudgetItems(4)}
                          buttonClass={budget === 4 ? "highlighted" : ""}
                          id={"Tabelas"}
                          name={"Tabelas"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <text style={{ color: "white" }}> Peças </text>
                        <DefaultButton
                          className="add-button"
                          label={"+"}
                          onClick={() => setOpen(2)}
                        />
                      </div>
                      <div className="bodyshop-body modal-budget modal-budget-gridtable fullscreen-gridtable">
                        <GridTable
                          columns={columnsPiece(
                            onRemovePiece,
                            changePartsValue,
                            isOpen,
                            setOpen,
                            openEditModal
                          )}
                          rows={parts}
                          enableColumnsReorder={false}
                          showSearch={false}
                          canReorder={false}
                          isPaginated={false}
                          showColumnVisibilityManager={false}
                          showRowsInformation={false}
                          texts={{
                            noResults: "Nenhum resultado foi encontrado",
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ minWidth: "49%" }}>
                      {isMobile ? (
                        <div>
                          <div
                            className="size-dropdown"
                            style={{ marginBottom: 5 }}
                          >
                            <text
                              style={{
                                color: "white",
                                fontStyle: "normal",
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                paddingLeft: isMobile ? "0px" : "10px",
                              }}
                            >
                              Tamanho
                            </text>
                            <div
                              className="void-class"
                              style={{ marginTop: "8px" }}
                            >
                              <SelectInput
                                // isSearchable={!isMobile}
                                options={sizeOptions}
                                placeholder="Selecione"
                                defaultValue={
                                  sizeOptions.find(
                                    (item) => item.id === values?.size
                                  ) || values?.size
                                }
                                value={sizeOptions.find(
                                  (item) => item.id === values?.size
                                )}
                                onChange={(value) => onChange("size", value)}
                                className="budget-percentage-input"
                              />
                            </div>
                          </div>
                          <PercentageInput
                            label={"Adicional s/ pintura"}
                            placeholder={"%"}
                            type="number"
                            value={values?.percentage}
                            onChange={(value) => onChange("percentage", value)}
                            className="budget-details-margin"
                          />
                          <div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: 3,
                                  alignItems: "center",
                                  gridGap: 10,
                                }}
                              >
                                <text
                                  style={{
                                    color: "white",
                                    textAlign: "end",
                                    fontSize: "14px",
                                    // fontWeight: "500",
                                    paddingLeft: isMobile ? "0px" : "10px",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  {" "}
                                  Hora linear{" "}
                                </text>
                                <ButtonSwitch
                                  value={values?.hour}
                                  hasLabel={false}
                                  defaultValue={values?.hour}
                                  onChange={(value) => {
                                    setIsntLinearHour(value);
                                    onChange("hour", value);
                                    onChange("price", 0);
                                  }}
                                />
                                <text
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    // fontWeight: "500",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  {" "}
                                  Hora tabela{" "}
                                </text>
                              </div>
                            </div>
                            {isntLinearHour ? (
                              <div className="budget-modal-main-div">
                                <div className="void-class">
                                  <SelectInput
                                    // isSearchable={!isMobile}
                                    options={hvTable}
                                    label={""}
                                    onChange={(value) =>
                                      onChange("price", value)
                                    }
                                    value={hvTable.find(
                                      (item) =>
                                        item.id == values?.price ||
                                        item.id == values?.price?.id
                                    )}
                                    defaultValue={hvTable.find(
                                      (item) =>
                                        item.id == values?.price ||
                                        item.id == values?.price?.id
                                    )}
                                    placeholder={"Selecione a tabela"}
                                    className="budget-percentage-input"
                                  />
                                </div>
                              </div>
                            ) : (
                              <MoneyInput
                                type="number"
                                value={values?.price}
                                onChange={(value) => onChange("price", value)}
                                placeholder={"Preço"}
                                hasLabel={false}
                                className="budget-details-margin"
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="budget-modal-main-div service-additional-on">
                          <div className="size-dropdown">
                            <text
                              style={{
                                color: "white",
                                fontStyle: "normal",
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              Tamanho{" "}
                            </text>
                            <div className="void-class">
                              <SelectInput
                                isSearchable={!isMobile}
                                options={sizeOptions}
                                placeholder="Selecione"
                                defaultValue={
                                  sizeOptions.find(
                                    (item) => item.id === values?.size
                                  ) || values?.size
                                }
                                value={sizeOptions.find(
                                  (item) => item.id === values?.size
                                )}
                                onChange={(value) => onChange("size", value)}
                              />
                            </div>
                          </div>
                          <PercentageInput
                            label={"Adicional s/ pintura"}
                            placeholder={"%"}
                            type="number"
                            value={values?.percentage}
                            onChange={(value) => onChange("percentage", value)}
                            className="budget-percentage-input"
                            classLabel="budget-label-input"
                          />
                          <div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: 3,
                                  alignItems: "center",
                                  gridGap: 10,
                                }}
                              >
                                <text
                                  style={{
                                    color: "white",
                                    textAlign: "end",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Hora linear{" "}
                                </text>
                                <ButtonSwitch
                                  value={values?.hour}
                                  defaultValue={values?.hour}
                                  hasLabel={false}
                                  onChange={(value) => {
                                    setIsntLinearHour(value);
                                    onChange("hour", value);
                                    onChange("price", 0);
                                  }}
                                />
                                <text
                                  style={{ color: "white", fontWeight: "600" }}
                                >
                                  {" "}
                                  Hora tabela{" "}
                                </text>
                              </div>
                            </div>
                            {isntLinearHour ? (
                              <div className="budget-modal-main-div">
                                <div className="void-class">
                                  <SelectInput
                                    options={hvTable}
                                    // isSearchable={!isMobile}
                                    label={""}
                                    onChange={(value) =>
                                      onChange("price", value)
                                    }
                                    value={hvTable.find(
                                      (item) =>
                                        item.id == values?.price ||
                                        item.id == values?.price?.id
                                    )}
                                    defaultValue={hvTable.find(
                                      (item) =>
                                        item.id == values?.price ||
                                        item.id == values?.price?.id
                                    )}
                                    placeholder={"Selecione a tabela"}
                                    className="budget-percentage-input"
                                  />
                                </div>
                              </div>
                            ) : (
                              <MoneyInput
                                type="number"
                                value={values?.price}
                                onChange={(value) => onChange("price", value)}
                                placeholder={"Preço"}
                                hasLabel={false}
                                className="budget-percentage-input"
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <text style={{ color: "white" }}> Serviços </text>
                        <DefaultButton
                          className="add-button"
                          label={"+"}
                          onClick={() => setOpen(1)}
                        />
                      </div>
                      <div className="bodyshop-body modal-budget modal-budget-gridtable fullscreen-gridtable">
                        <GridTable
                          columns={columnsService(
                            onRemoveService,
                            values,
                            changeValue
                          ).map((column, index) => ({
                            ...column,
                            style: {
                              backgroundColor:
                                index % 2 === 0
                                  ? "gray !important"
                                  : "white !important",
                            },
                          }))}
                          rows={service}
                          enableColumnsReorder={false}
                          showSearch={false}
                          canReorder={false}
                          isPaginated={false}
                          showColumnVisibilityManager={false}
                          showRowsInformation={false}
                          texts={{
                            noResults: "Nenhum resultado foi encontrado",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="values-box">
                    <h4 className="values-title">
                      Total peças:{" "}
                      <span className="values-span">
                        {(totalParts ?? 0).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                    <h4 className="values-title">
                      Total serviços:{" "}
                      <span className="values-span">
                        {(totalService ?? 0).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                    <h4 className="values-title">
                      Total orçamento:{" "}
                      <span className="values-span">
                        {totalSum.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </h4>
                  </div>
                </div>
              )}
              {values && !values.id ? (
                <div />
              ) : (
                <div>
                  <div
                    className="header-title"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: isMobile ? 10 : 20,
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flex: isMobile ? 1 : 0,
                      }}
                    >
                      <DefaultButton
                        className="modal-print modal-footer-button cancel margin-0"
                        label={"IMPRIMIR"}
                        icon={
                          <AiOutlinePrinter className="button-icon-cancel" />
                        }
                        onClick={toPrint}
                      />
                      <DefaultButton
                        className="modal-footer-button cancel to-share-button"
                        label={"ENVIAR"}
                        icon={
                          <AiOutlineWhatsApp className="button-icon-cancel" />
                        }
                        onClick={sendWhatsapp}
                      />
                    </div>
                    <DefaultButton
                      className="modal-footer-button cancel margin-0"
                      label={"COMPARTILHAR"}
                      icon={<AiOutlineSend className="button-icon-cancel" />}
                      onClick={toShare}
                      disabled={isLoading}
                    />
                    {isLoading && (
                      <div className="spinner-clear">
                        <Spinner color="primary" size="sm" />
                      </div>
                    )}
                    <DefaultButton
                      className="modal-footer-button delete"
                      label={"Excluir"}
                      icon={<BsFillTrashFill className="button-icon-cancel" />}
                      onClick={onRemove}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BudgetAddScreen
        compareValues={compareValues}
        service={service}
        addedServices={addedServices}
        serviceType={serviceType}
        setServiceType={setServiceType}
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        setAddBudget={setAddBudget}
        isOpen={open === 1}
        values={values}
        isntLinearHour={isntLinearHour}
        setValues={(newValues) => {
          const updatedValues = Array.isArray(newValues)
            ? newValues
            : [newValues];
          const filteredValues = updatedValues.filter(
            (value) => value && value.title
          );

          if (filteredValues.length > 0) {
            setService((service) => [...service, ...filteredValues]);
          }
        }}
        setOpen={setOpen}
      />
      <PieceAddScreen
        addedParts={addedParts}
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        isOpen={open === 2}
        partsArray={parts}
        setValues={(values) => setParts((part) => [...part, ...values])}
        setOpen={setOpen}
      />
      <BudgetAddClient
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        isOpen={open === 3}
        setReload={setReload}
        setOpen={setOpen}
        createOrigin={createOrigin}
      />
      <BudgetAddVehicle
        showBudgetItems={showBudgetItems}
        budget={addBudget}
        isOpen={open === 4}
        setOpen={setOpen}
        setReload={setReload}
        defaultValues={values}
      />
      <BudgetPrint
        ref={ref}
        reloadPrint={reloadPrint}
        company={printCompany}
        values={values}
        client={client}
        totalSum={totalSum}
        totalService={totalService}
        totalParts={totalParts}
        isLoading={isLoading}
      />
      <PieceEdit
        isOpen={isOpen}
        setOpen={setIsOpen}
        parts={parts}
        setParts={setParts}
        part={part}
        setPart={setPart}
        values={values}
        onChangePiece={onChangePiece}
      />
    </>
  );
};

export default ModalBudget;

const getTotalSum = (service, parts) => {
  let totalSum = 0;
  for (const item of service) {
    totalSum += parseFloat(item.value) || 0;
  }
  for (const item of parts) {
    totalSum += parseFloat(item.value) || 0;
  }

  return totalSum;
};

export const sizeOptions = [
  { id: 2, value: "P", label: "P" },
  { id: 3, value: "M", label: "M" },
  { id: 4, value: "G", label: "G" },
];

export const statusOptions = [
  { id: 4, value: "Pendente", label: "Pendente", color: "#b36a22" },
  { id: 1, value: "Encerrado", label: "Encerrado", color: "#c95555" },
  { id: 2, value: "Enviado", label: "Enviado", color: "#b5b5b5" }, //check
  { id: 3, value: "Aprovado", label: "Aprovado", color: "#00A86B" }, //check
  {
    id: 5,
    value: "Aprovado Parcial",
    label: "Aprovado Parcial",
    color: "#6eb6ff",
  },
  { id: 6, value: "Repescado", label: "Repescado", color: "#eead2d " },
  { id: 7, value: "Reprovado", label: "Reprovado", color: "#c95555 " },
];

export const statusBadges = [
  { id: 3, value: <FaCheck />, label: "Aprovado" },
  { id: 5, value: <FaMagnifyingGlass />, label: "Analisando" },
  { id: 6, value: <FaQuestion />, label: "Em dúvida" },
];

const columnsService = (onRemove, values, changeValue) => [
  {
    id: 1,
    field: "title",
    label: "Serviço",
    width: "1fr",
    className: "field-description",
    resizable: false,
    cellRenderer: ({ data }) => {
      const handleTimeValueChange = (value) => {
        changeValue("time", data, value, data?.editedHour ? data?.editedHour : data.time !== value);
      };

      const handleUnitaryValueChange = (value) => {
        changeValue("unitaryValue", data, value);
      };

      return (
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
          <div style={{ paddingLeft: "10px" }}>{data.title}</div>
          <div
            style={{
              gap: "5px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <div className="field-actions-budget budget-service-hour">
              <HourInput
                type="number"
                className="budget-service-input"
                onChange={handleTimeValueChange}
                placeholder={"Valor hora"}
                value={data.time}
              />
            </div>
            <div className="field-actions-budget budget-service-unit">
              <MoneyInput
                type="number"
                className="budget-service-input"
                onChange={handleUnitaryValueChange}
                placeholder={"Valor unit"}
                value={data.unitaryValue}
              />
            </div>
            <div className="field-actions-budget budget-service-total">
              <MoneyInput
                type="number"
                disabled={true}
                className="budget-service-input"
                onChange={(value) => changeValue("value", data, value)}
                value={isNaN(data.value) ? "R$0" : data.value}
              />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: 5,
    field: "actions",
    label: "Ações",
    resizable: false,
    width: "90px",
    cellRenderer: ({ data }) => <ActionCell data={data} onRemove={onRemove} />,
  },
];

const columnsPiece = (
  onRemove,
  changePartsValue,
  isOpen,
  setOpen,
  openEditModal
) => [
  {
    id: 1,
    field: "name",
    label: "Peça",
    width: "1fr",
    className: "field-description",
    resizable: false,
    cellRenderer: (props) => {
      const { data } = props;
      const handleQuantityValueChange = (value) => {
        if (data.quantity !== value) {
          changePartsValue("quantity", data, value);
        }
      };

      const handlePartsUnitaryValueChange = (value) => {
        changePartsValue("unitaryValue", data, value);
      };

      const handlePartsTotal = (value) => {
        changePartsValue("value", data, value);
      };

      return (
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
          <div style={{ paddingLeft: "10px" }}>{data.name}</div>
          <div
            style={{
              gap: "5px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
            }}
          >
            <div className="field-actions-budget budget-piece-quantity">
              <LabelInput
                type="text"
                className="budget-service-input"
                onChange={handleQuantityValueChange}
                placeholder={"QUANTIDADE"}
                value={data.quantity}
              />
            </div>
            <div className="field-actions-budget budget-piece-unit">
              <MoneyInput
                type="number"
                className="budget-service-input"
                onChange={handlePartsUnitaryValueChange}
                placeholder={"Valor unit"}
                value={
                  data.value && data.quantity
                    ? data?.profit ||
                      data?.profit_margin ||
                      data?.discount ||
                      data?.discount_percentage
                      ? parseFloat(data.value) / data.quantity
                      : data.unitaryValue
                    : data.unitaryValue
                }
              />
            </div>
            <div className="field-actions-budget budget-piece-total">
              <MoneyInput
                type="number"
                disabled={true}
                className="budget-service-input"
                onChange={handlePartsTotal}
                value={
                  isNaN(parseFloat(data.value)) ? "R$0" : parseFloat(data.value)
                }
              />
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: 5,
    field: "actions",
    label: "Ações",
    resizable: false,
    width: "90px",
    cellRenderer: ({ data }) => (
      <PieceActionCell
        data={data}
        onRemove={onRemove}
        isOpen={isOpen}
        setOpen={setOpen}
        openEditModal={openEditModal}
      />
    ),
  },
];

const ActionCell = ({ data, onRemove }) => {
  return (
    <div className="field-actions">
      <BsTrashFill
        className="icon-action trash"
        onClick={() => onRemove(data)}
      />
    </div>
  );
};

const PieceActionCell = ({ data, onRemove, setOpen, openEditModal }) => {
  return (
    <div className="field-actions">
      <FaPercentage
        className="icon-action edit"
        onClick={() => openEditModal(data)}
      />
      <BsTrashFill
        className="icon-action trash"
        onClick={() => onRemove(data)}
      />
    </div>
  );
};
