import React, { useEffect, useState } from "react";
import AestheticScreen from "../../screens/Aesthetic/AestheticScreen";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { compareValues } from "../ModalClients/ModalClientsContainer";
import Swal from "sweetalert2";

const AestheticContainer = ({ }) => {
    const aesthetic = useSelector(state => state.app.aesthetic || 1)
    const [data, setData] = useState([])
    const history = useHistory()
    const company = useSelector(state => state.app.company || {})
    const user = useSelector(state => state.app.user || {})
    const [oldValues, setOldValues] = useState([])
    const [values, setValues] = useState([])
    const dispatch = useDispatch()
    const reloadBodyshop = useSelector(state => state.app.reloadAesthetic || false)

    useEffect(() => {
        loadData()
    }, [company, aesthetic])

    const loadData = async () => {
        setData([
            {
                serviceType: 'paintjob',
                name: 'Pintura',
                value: 1,
            },
        ])
    }

    const closeModal = () => {
        if (compareValues(oldValues, values)) {
            Swal.fire({
                title: 'Deseja sair sem salvar?',
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                icon: 'warning',
                text: 'Ao sair, seus dados seram descartados.',
                denyButtonText: `Descartar`,
                cancelButtonText: 'Cancelar',

            }).then((result) => {
                if (result.isDenied) {
                    history.push('/aesthethic')
                }
            })

        } else {
            history.push('/aesthetic')
        }
    }

    return (
        <AestheticScreen
            aesthetic={aesthetic}
            compareValues={compareValues(oldValues, values)}
            closeModal={closeModal}
            data={filteredData(data, aesthetic)}
            user={user}
        />
    )
}

export default AestheticContainer

const filteredData = (data, filter) => {
    return (
        data && data.filter(item => item.value === filter)
    )
}