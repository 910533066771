import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import HeaderScreen from "../../screens/HeaderScreen/index";
import { menuRoutes } from "../../core/router/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  setData as setDispatch,
  setLogout,
} from "../../core/redux/app/Actions";
import { sort } from "fast-sort";

const HeaderContainer = () => {
  const navigate = useSelector((state) => state.app.navigate);
  const user = useSelector((state) => state.app.user || {});
  const company = useSelector(
    (state) => state.app.company || { id: -1, name: "Prospera" }
  );
  let history = useHistory();
  const route = menuRoutes(user, company).find(
    (item) => item.path === navigate
  );
  const [data, setData] = useState([]);
  const reloadCompany = useSelector(
    (state) => state.app.reloadCompany || false
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.length > 0) {
      const ordenateData = sort(data).asc((u) => u.id);
      const newCompany = ordenateData[0];
      if (newCompany && newCompany.id && (!company || company?.id === -1)) {
        dispatch(setDispatch({ company: newCompany }));
      }
    }
  }, [data]);

  useEffect(() => {
    loadData();
  }, [reloadCompany]);

  const loadData = async () => {
    let value = {};

    if (user && [1, 2].includes(user.id)) {
      let { data, error } = await supabase
        .from("Company")
        .select("*")
        .is("deleted_at", null);

      value = { data, error };
    } else {
      const { data, error } = await supabase
        .from("UserCompany")
        .select(
          `
            type,
            company (
                *
            )
          `
        )
        .eq("user", user.id)
        .is("deleted_at", null)
        .is("company.deleted_at", null);

      if (!error) {
        const newData = data
          .filter(({ company }) => company)
          .map(({ company, type }) => ({ ...company, type }));
        const ordenateData = sort(newData).asc((u) => u.id);
        value = { data: ordenateData, error };
      } else {
        value = { data, error };
      }
    }

    const { data, error } = value;

    if (!error) {
      setData(data);
    }
  };

  const onLogout = () => {
    supabase.auth.signOut();
    history.push("/");
    dispatch(setLogout({}));
  };

  const changeCompany = (company) => {
    // setSelected(company)
    if (company && company.id) {
      dispatch(setDispatch({ company })); //navigate: "/dashboard"
      // history.push("/dashboard");
    }
  };

  return (
    <HeaderScreen
      onLogout={onLogout}
      route={route}
      data={data}
      selected={company}
      changeCompany={changeCompany}
      history={history}
    />
  );
};

export default HeaderContainer;
