import React from "react";
import { LabelInput, TextInput } from "../../components/Input/Input";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { PercentageInput } from "../../components/Input/Input";
import { Button } from "reactstrap";
import { BsFillTrashFill } from "react-icons/bs";
import { isMobile } from "react-device-detect";

const ModalServiceType = ({
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  onRemove,
  addSubService,
  onDeleteService,
  setServiceValues,
  subService,
  serviceValues,
  compareValues,
}) => {
  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Tipo de serviço</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? "" : "Salvar"}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? "" : "Cancelar"}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div className="bodyshop-title">
          <text>
            {isNew ? (
              <TextInput
                value={values.title}
                placeholder="Adicionar título"
                onChange={(event) =>
                  onChange("title", event.target.value.toUpperCase())
                }
                className="item-title"
              />
            ) : (
              <TextInput
                value={values.title}
                placeholder={values.title}
                onChange={(event) =>
                  onChange("title", event.target.value.toUpperCase())
                }
                className="item-title"
              />
            )}
          </text>
        </div>
        <br />
        <div className="main-upper-div">
          <div className="service-type-description-placement">
            <LabelInput
              className="service-type-description"
              value={serviceValues.description}
              onChange={(value) =>
                setServiceValues((values) => ({
                  ...values,
                  description: value,
                }))
              }
              placeholder={"Insira a descrição"}
              label={"Descrição"}
            />
          </div>
          <div className="service-type-percentage-placement">
            <PercentageInput
              className="description-percentage-add"
              value={serviceValues.percent}
              onChange={(value) => {
                const newAmount = parseInt(value);
                if (newAmount > 100) {
                  setServiceValues((values) => ({ ...values, percent: "100" }));
                } else {
                  setServiceValues((values) => ({ ...values, percent: value }));
                }
              }}
              label={"Percentual"}
              placeholder={"%"}
            />
          </div>
          <div style={{ paddingTop: "30px" }}>
            <DefaultButton
              className="percentage-button"
              onClick={addSubService}
              label={"+"}
            />
          </div>
        </div>
        <div className="lower-div">
          <div>
            <text
              style={{
                color: "white",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {" "}
              Sub serviços
            </text>
          </div>
          {subService &&
            subService.map((item) => (
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <LabelInput
                    className="subservice-item"
                    disabled={true}
                    value={item.description}
                  />
                </div>
                <div style={{ width: "29.5%", marginLeft: 5, marginRight: 5 }}>
                  <PercentageInput
                    className="subservice-percent"
                    disabled={true}
                    value={item.percent}
                    onChange={(value) => {
                      const newAmount = parseInt(value);
                      if (newAmount > 100) {
                        onChange("percents", { ...item, percent: "100" });
                      } else {
                        onChange("percents", { ...item, percent: value });
                      }
                    }}
                  />
                </div>
                <div
                  className="delete-service-placement"
                  style={{ paddingTop: 10, paddingRight: 5, paddingLeft: 10 }}
                >
                  <Button
                    style={{
                      borderColor: "#ffaaa4",
                      backgroundColor: "transparent",
                      height: 40,
                      width: 50,
                      borderRadius: 5,
                    }}
                    onClick={() => onDeleteService(item)}
                  >
                    <BsFillTrashFill className="button-icon-delete" />
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="modal-footer-table">
        {values && !values.id ? (
          <div />
        ) : (
          <DefaultButton
            className="modal-footer-button delete"
            label={"Excluir"}
            icon={<BsFillTrashFill className="button-icon-cancel" />}
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ModalServiceType;
