import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setData } from "../../core/redux/app/Actions";
import { supabase } from "../../supabaseClient";
import { compareValues } from "../ModalClients/ModalClientsContainer";
import Swal from "sweetalert2";
import ModalServiceAesthetic from "../../screens/ModalServiceAesthetic/ModalServiceAesthetic";
import { saveAeService } from "../../core/utils/AeServiceType";
import { alertError, alertSuccess } from "../../components/Toast";

const ModalServiceAestheticContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isNew = params.id === "0";
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const reloadAeServices = useSelector(
    (state) => state.app.reloadAeServices || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});

  useEffect(() => {
    if (location && location.state) {
      setOldValues({ ...location.state });
      setValues({ ...location.state });
    }
  }, [location]);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    dispatch(
      setData({ [`compareValues_${path}`]: compareValues(oldValues, values) })
    );
  }, [values, oldValues]);

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados serão descartados.",
        denyButtonText: `Descartar`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/aesthetic");
        }
      });
    } else {
      history.push("/aesthetic");
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    try {
      if (values && !values.title) {
        alertError("Título é obrigatório.");
        return;
      }

      // if (values && (!values.p_time || !values.p_value)) {
      //     alertError('Tempo ou valor para carros pequenos é obrigatório.')
      //     return
      // }

      // if (values && (!values.m_time || !values.m_value)) {
      //     alertError('Tempo ou valor para carros médios é obrigatório.')
      //     return
      // }

      // if (values && (!values.g_time || !values.g_value)) {
      //     alertError('Tempo ou valor para carros grandes é obrigatório.')
      //     return
      // }

      const save = await saveAeService(values.id, {
        ...values,
        id_company: company.id,
      });
      alertSuccess("Sucesso ao salvar o serviço!");
      history.push("/aesthetic");
      dispatch(setData({ reloadAeServices: !reloadAeServices }));
    } catch (error) {
      alertError("Erro ao salvar o serviço, tente novamente mais tarde.");
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: "Deseja mesmo excluir?",
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: "warning",
      text: "Seus dados serão completamente perdidos!",
      denyButtonText: "Excluir",
      cancelButtonText: "Cancelar",
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from("AeServices")
          .update({ deleted_at: new Date(), deleted_by: user.id })
          .eq("id", values.id);

        if (!error) {
          alertSuccess("Removido com sucesso!");
          history.push("/aesthetic");
          dispatch(setData({ reloadAeServices: !reloadAeServices }));
        } else {
          alertError("Erro ao remover, tente novamente mais tarde");
        }
      } else {
        alertError("Erro ao remover, tente novamente mais tarde");
      }
    } else {
      history.push("/aesthetic");
    }
  };

  return (
    <ModalServiceAesthetic
      compareValues={compareValues(oldValues, values)}
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      isNew={isNew}
      onSave={onSave}
      onRemove={onRemove}
    />
  );
};

export default ModalServiceAestheticContainer;
