import {
  BsCart,
  BsClipboard,
  BsGear,
  BsHouseDoor,
  BsPerson,
  BsBuilding,
  BsCalculator,
} from "react-icons/bs";
import { BiCar } from "react-icons/bi";
import LoginContainer from "../../containers/Login/LoginContainer";
import RecoveryContainer from "../../containers/Recovery/RecoveryContainer";
import NewPasswordContainer from "../../containers/NewPassword/NewPasswordContainer";
import ExternalBudgetContainer from "../../containers/ExternalBudget/ExternalBudgetContainer";
import { RiLockPasswordLine } from "react-icons/ri";
import ChangePasswordContainer from "../../containers/ChangePassword/ChangePasswordContainer";
import CompanyContainer from "../../containers/Company/CompanyContainer";
import ClientsContainer from "../../containers/Clients/ClientsContainer";
import VehiclesContainer from "../../containers/Vehicles/VehiclesContainer";
import ModalVehiclesContainer from "../../containers/ModalVehicles/ModalVehiclesContainer";
import ModalCompanyContainer from "../../containers/ModalCompany/ModalCompanyContainer";
import ModalClientsContainer from "../../containers/ModalClients/ModalClientsContainer";
import { DefaultButton } from "../../components/Button/Button";
import { MdAdd } from "react-icons/md";
import BodyShopContainer from "../../containers/BodyShop/BodyShopContainer";
import { DropDownTitle } from "../../components/DropDown";
import ModalBodyShopContainer from "../../containers/ModalBodyShop/ModalBodyShopContainer";
import AestheticContainer from "../../containers/Aesthetic/AestheticContainer";
import ModalAestheticContainer from "../../containers/ModalAesthetic/ModalAestheticContainer";
import ModalBudgetContainer from "../../containers/ModalBudget/ModalBudgetContainer";
import BudgetContainer from "../../containers/Budget/BudgetContainer";
import ModulesContainer from "../../containers/Modules/ModulesContainer";
import ModalAddPartsContainer from "../../containers/ModalAddParts/ModalAddPartsContainer";
import PartsContainer from "../../containers/Parts/PartsContainer";
import DashboardContainer from "../../containers/Dashboard/DashboardContainer";
import { isMobile } from "react-device-detect";
import RegisterContainer from "../../containers/Register/RegisterContainer";
import HomeButton from "../../components/homeButton/homeButton";

export const openRoutes = [
  {
    path: "/login",
    Component: LoginContainer,
  },
  {
    path: "/recovery",
    title: "Acesso",
    icon: <BsClipboard className="small" />,
    Component: RecoveryContainer,
  },
  {
    path: "/reset-password",
    icon: <RiLockPasswordLine className="small" />,
    Component: NewPasswordContainer,
  },
  {
    path: "/external-budget/:id",
    title: "Orçamento",
    icon: <RiLockPasswordLine className="small" />,
    Component: ExternalBudgetContainer,
  },
];

export const menuRoutes = (user, company) => {
  return [
    {
      path: "/home",
      title: "Início",
      icon: <BsHouseDoor className="small" />,
      mobile: true,
      Component: DashboardContainer,
      details: (props) => <DashboardContainer {...props} />,
    },
    {
      path: "/budgets",
      title: "Orçamentos",
      icon: <BsCalculator className="small" />,
      Component: BudgetContainer,
      details: (props) => <ModalBudgetContainer {...props} />,
      action:
        (user && [1, 2].includes(user.id)) ||
        (company && company.type === 1) ||
        (company && company.type === 2)
          ? (history) => (
              <>
                <HomeButton />
                <DefaultButton
                  className="new-table-button"
                  label={"Adicionar"}
                  icon={<MdAdd className="button-icon" />}
                  onClick={() => history.push(`/budgets/details/0`)}
                />
              </>
            )
          : null,
    },
    (user && [1, 2].includes(user.id)) || (company && company.type === 1)
      ? {
          path: "/company",
          title: "Empresas",
          icon: <BsBuilding className="small" />,
          Component: CompanyContainer,
          details: (props) => <ModalCompanyContainer {...props} />,
          action:
            user && [1, 2].includes(user.id)
              ? (history) => (
                  <>
                    <HomeButton />
                    <DefaultButton
                      className="new-table-button"
                      label={"Adicionar"}
                      icon={<MdAdd className="button-icon" />}
                      onClick={() => history.push(`/company/details/0`)}
                    />
                  </>
                )
              : null,
        }
      : null,
    {
      path: "/clients",
      title: "Clientes",
      icon: <BsPerson className="small" />,
      Component: ClientsContainer,
      details: (props) => <ModalClientsContainer {...props} />,
      action:
        (user && [1, 2].includes(user.id)) ||
        (company && company.type === 1) ||
        (company && company.type === 2)
          ? (history) => (
              <>
                <HomeButton />
                <DefaultButton
                  className="new-table-button"
                  label={"Adicionar"}
                  icon={<MdAdd className="button-icon" />}
                  onClick={() => history.push(`/clients/details/0`)}
                />
              </>
            )
          : null,
    },
    {
      path: "/vehicles",
      title: "Veículos",
      icon: <BiCar className="small" />,
      Component: VehiclesContainer,
      details: (props) => <ModalVehiclesContainer {...props} />,
      action:
        (user && [1, 2].includes(user.id)) ||
        (company && company.type === 1) ||
        (company && company.type === 2)
          ? (history) => (
              <>
                <HomeButton />
                <DefaultButton
                  className="new-table-button"
                  label={"Adicionar"}
                  icon={<MdAdd className="button-icon" />}
                  onClick={() => history.push(`/vehicles/details/0`)}
                />
              </>
            )
          : null,
    },
    // {
    //   path: "/modules",
    //   title: "Módulos",
    //   icon: <BiCar className="small" />,
    //   Component: ModulesContainer,
    //   details: (props) => <ModulesContainer {...props} />,
    // },
    {
      path: "/modules",
      title: "Módulos",
      icon: <BsGear className="small" />,
      Component: ModulesContainer,
      details: (props) => <ModulesContainer {...props} />,
      action: () => (
        <>
          <HomeButton />
        </>
      ),
      childs: [
        {
          path: "/bodyshop",
          title: "Funilaria e pintura",
        },
        // {
        //     path: "/aesthetic",
        //     title: "Estética",
        // },
        {
          path: "/parts",
          title: "Peças",
        },
      ],
    },
    {
      path: "/register",
      title: "Cadastro",
      hidden: true,
      icon: <BsGear className="small" />,
      Component: RegisterContainer,
      details: (props) => <RegisterContainer {...props} />,
      action: () => (
        <>
          <HomeButton />
        </>
      ),
    },
    {
      path: "/bodyshop",
      title: (
        <div className="route-title">
          <DropDownTitle
            style={{
              display: "flex",
              paddingLeft: 10,
              marginBottom: "5px",
              fontSize: "large",
              lineHeight: "normal",
            }}
            module={1}
            options={[
              {
                id: 1,
                name: "Tipos de serviço",
              },
              {
                id: 2,
                name: "Etapas do processo",
              },
              {
                id: 3,
                name: "Valor x Hora",
              },
              {
                id: 4,
                name: "Serviços",
              },
            ]}
          />
        </div>
      ),
      hidden: true,
      icon: <BiCar className="small" />,
      Component: BodyShopContainer,
      details: (props) => <ModalBodyShopContainer {...props} />,
      action:
        (user && [1, 2].includes(user.id)) ||
        (company && company.type === 1) ||
        (company && company.type === 2)
          ? (history) => (
              <>
                <HomeButton />
                <DefaultButton
                  className="new-table-button"
                  label={"Adicionar"}
                  icon={<MdAdd className="button-icon" />}
                  onClick={() => history.push(`/bodyshop/details/0`)}
                />
              </>
            )
          : null,
    },
    {
      path: "/aesthetic",
      title: (
        <div className="route-title">
          <span
            style={{
              paddingTop: "8px",
              fontSize: "18px  ",
              paddingRight: "15px",
            }}
          >
            Estética
          </span>
          <DropDownTitle
            style={{ display: "flex", paddingLeft: 10 }}
            module={2}
            options={[
              {
                id: 1,
                name: "Etapas do processo",
              },
              {
                id: 2,
                name: "Serviços",
              },
            ]}
          />
        </div>
      ),
      hidden: true,
      icon: <BiCar className="small" />,
      Component: AestheticContainer,
      details: (props) => <ModalAestheticContainer {...props} />,
      action:
        (user && [1, 2].includes(user.id)) ||
        (company && company.type === 1) ||
        (company && company.type === 2)
          ? (history) => (
              <>
                <HomeButton />
                <DefaultButton
                  className="new-table-button"
                  label={"Adicionar"}
                  icon={<MdAdd className="button-icon" />}
                  onClick={() => history.push(`/aesthetic/details/0`)}
                />
              </>
            )
          : null,
    },
    {
      path: "/parts",
      title: "Peças",
      hidden: true,
      icon: <BiCar className="small" />,
      Component: PartsContainer,
      details: (props) => <ModalAddPartsContainer {...props} />,
      action:
        (user && [1, 2].includes(user.id)) ||
        (company && company.type === 1) ||
        (company && company.type === 2)
          ? (history) => (
              <>
                <HomeButton />

                <DefaultButton
                  className="new-table-button"
                  label={"Adicionar"}
                  icon={<MdAdd className="button-icon" />}
                  onClick={() => history.push(`/parts/details/0`)}
                />
              </>
            )
          : null,
    },
  ].filter((item) => item);
};

export const firstRoute = [
  {
    path: "/change-password",
    hidden: true,
    Component: ChangePasswordContainer,
  },
];

export const externalRoute = [
  {
    path: "/external-budget/:id",
    hidden: true,
    Component: ExternalBudgetContainer,
  },
];
