import React from "react"
import ModalServiceAestheticContainer from "../../containers/ModalServiceAestheticContainer/ModalServiceAestheticContainer";
import ModalProductionPhasesAestheticContainer from "../../containers/ModalProductionPhaseAestheticContainer/ModalProductionPhaseAestheticContainer";

const ModalAesthetic = ({ aesthetic }) => {
    
    return (
        <div>
            <div>
                {aesthetic === 1 && <ModalProductionPhasesAestheticContainer/>}
                {aesthetic === 2 && <ModalServiceAestheticContainer />}
            </div>
        </div>
    );
}

export default ModalAesthetic