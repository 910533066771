import { LabelInput, HourInput, TextInput } from "../../components/Input/Input";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { BsFillTrashFill } from "react-icons/bs";
import ButtonSwitch from "../../components/Switch";
import { isMobile } from "react-device-detect";

const ModalServiceAesthetic = ({
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
}) => {
  return (
    <div className="modal-service-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Serviços</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? "" : "Salvar"}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? "" : "Cancelar"}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div
          style={{ paddingLeft: 10, paddingRight: 10 }}
          className="bodyshop-title"
        >
          <text>
            {isNew ? (
              <TextInput
                value={values.title}
                placeholder="Adicionar título"
                onChange={(event) =>
                  onChange("title", event.target.value.toUpperCase())
                }
                className="item-title"
              />
            ) : (
              <TextInput
                value={values.title}
                placeholder={values.title}
                onChange={(event) =>
                  onChange("title", event.target.value.toUpperCase())
                }
                className="item-title"
              />
            )}
          </text>
        </div>
        <br />
        <div className="main-upper-div">
          <div className="service-description">
            <LabelInput
              value={values.description}
              onChange={(value) => onChange("description", value)}
              placeholder={"Insira a descrição"}
              label={"Descrição"}
            />
          </div>
        </div>
        <div className="lower-aesthetic-div">
          <div style={{ paddingTop: "15px" }}>
            <text style={{ color: "white", fontWeight: 500, fontSize: 14 }}>
              Tabela
            </text>
            <div style={{ display: "flex", paddingBottom: 25 }}>
              <div>
                <div className="service-size-content">
                  <LabelInput
                    disabled={true}
                    className={"small-size-input"}
                    placeholder={"P"}
                    label={""}
                  />
                  <LabelInput
                    disabled={true}
                    className={"small-size-input"}
                    placeholder={"M"}
                    label={""}
                  />
                  <LabelInput
                    disabled={true}
                    className={"small-size-input"}
                    placeholder={"G"}
                    label={""}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: 1,
                    height: 160,
                    backgroundColor: "gray",
                    marginTop: 30,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "0px 0px 0px 10px",
                }}
              >
                <div className="first-service-size-content">
                  <HourInput
                    className={"hour-input"}
                    onChange={(value) => onChange("p_time", value)}
                    label={""}
                    value={values.p_time}
                    type="number"
                  />
                  <HourInput
                    className={"hour-input"}
                    onChange={(value) => onChange("m_time", value)}
                    label={""}
                    value={values.m_time}
                    type="number"
                  />
                  <HourInput
                    className={"hour-input"}
                    onChange={(value) => onChange("g_time", value)}
                    label={""}
                    value={values.g_time}
                    type="number"
                  />
                </div>
                <div className="middle-service-size-content">
                  <LabelInput
                    className={"normal-size-input"}
                    onChange={(value) => onChange("p_value", value)}
                    label={""}
                    placeholder={"___"}
                    value={values.p_value}
                    type="number"
                  />
                  <LabelInput
                    className={"normal-size-input"}
                    onChange={(value) => onChange("m_value", value)}
                    label={""}
                    placeholder={"___"}
                    value={values.m_value}
                    type="number"
                  />
                  <LabelInput
                    className={"normal-size-input"}
                    onChange={(value) => onChange("g_value", value)}
                    label={""}
                    placeholder={"___"}
                    value={values.g_value}
                    type="number"
                  />
                </div>
                <div className="last-service-size-content">
                  <LabelInput
                    className={"normal-size-result"}
                    disabled={true}
                    value={
                      values.p_time && values.p_value
                        ? fixMoney(values.p_time, values.p_value)
                        : ""
                    }
                    label={""}
                    type="number"
                  />
                  <LabelInput
                    className={"normal-size-result"}
                    disabled={true}
                    value={
                      values.m_time && values.m_value
                        ? fixMoney(values.m_time, values.m_value)
                        : ""
                    }
                    label={""}
                    type="number"
                  />
                  <LabelInput
                    className={"normal-size-result"}
                    disabled={true}
                    value={
                      values.g_time && values.g_value
                        ? fixMoney(values.g_time, values.g_value)
                        : ""
                    }
                    label={""}
                    type="number"
                  />
                </div>
              </div>
              {/* <div style={{ marginTop: 5 }}>
                <ActiveSwitch />
              </div> */}
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            <ButtonSwitch
              value={values.active}
              onChange={(value) => onChange("active", value)}
            />
          </div>
        </div>
      </div>

      <div className="modal-footer-table">
        {values && !values.id ? (
          <div />
        ) : (
          <DefaultButton
            className="modal-footer-button delete"
            label={"Excluir"}
            icon={<BsFillTrashFill className="button-icon-cancel" />}
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ModalServiceAesthetic;

const fixMoney = (value1, value2) => {
  const number = formatarHoraParaNumero(value1) * value2;
  return number.toFixed(2);
};

const formatarHoraParaNumero = (hora) => {
  const partes = hora.split(":");
  const horas = parseInt(partes[0], 10);
  const minutos = partes[1] ? parseInt(partes[1], 10) : 0;
  return horas + minutos / 60;
};
