import { supabase } from "../../supabaseClient"

export const saveAeProductionPhase = async (id = undefined, item) => {
    if (id) {
        return await supabase
            .from('AeProcSteps')
            .update({ ...item })
            .match({ id })
    } else {
        return await supabase
            .from('AeProcSteps')
            .insert([{ ...item }])
    }
}