import React, { useEffect } from "react"
import { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BiBrush, BiReset } from "react-icons/bi";
import { FiScissors } from "react-icons/fi";
import { BsPencilFill } from "react-icons/bs";
import ServiceScreen from "../../screens/Service/ServiceScreen";
import { supabase } from "../../supabaseClient";
import { useSelector } from "react-redux";

const ServiceContainer = ({ }) => {
    const [data, setData] = useState([])
    const company = useSelector(state => state.app.company || {})
    const reloadServices = useSelector(state => state.app.reloadServices || false)

    useEffect(() => {
        loadData()
    }, [company, reloadServices])

    const loadData = async () => {
        let { data, error } = await supabase
            .from('Services')
            .select('*')
            .eq("id_company", company.id)
            .is('deleted_at', null)
        if (!error) {
            setData(data)
        }
    }

    return (
        <ServiceScreen
            columns={columns}
            rows={data}
        />
    )

}

export default ServiceContainer

const columns = [
    {
        id: 1,
        field: 'title',
        label: 'Serviços',
        width: '1fr',
        paddingLeft: '30px !important',
        resizable: false,
    },
    {
        id: 2,
        field: 'actions',
        label: 'Ações',
        pinned: true,
        resizable: false,
        width: '70px',
        cellRenderer: ({ data }) => <ActionCell data={data} />,
    },
];

function obterIcone(serviceType) {
    const icones = {
        paintjob: <BiBrush className='body-shop-icons' />,
        taphestry: <FiScissors className='body-shop-icons' />,
        recover: <BiReset className='body-shop-icons' />,
    };

    return icones[serviceType] || null;
}

const ActionCell = ({ data }) => {
    let history = useHistory();
    return (
        <div className='field-actions'>
            <BsPencilFill className='icon-action edit' onClick={() => history.push(`/bodyshop/details/${data.id}`, data)} />
        </div>
    );
};