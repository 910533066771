import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BsPencilFill } from "react-icons/bs";
import { BiBrush, BiReset } from "react-icons/bi";
import { FiScissors } from "react-icons/fi";
import ValueHourChartScreen from "../../screens/ValueHourChart/ValueHourChartScreen";
import { supabase } from "../../supabaseClient";
import { sort } from "fast-sort";
import { IoWarning } from "react-icons/io5";

const ValueHourChartContainer = ({}) => {
  const [data, setData] = useState([]);
  const company = useSelector((state) => state.app.company || {});
  const reloadValueHour = useSelector(
    (state) => state.app.reloadValueHour || false
  );

  useEffect(() => {
    loadData();
  }, [company, reloadValueHour]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from("HourlyValue")
      .select("*")
      .eq("id_company", company.id)
      .is("deleted_at", null);
    if (!error) {
      const ordenateData = sort(
        data.map((item, index) => ({ ...item, index: item.index || index }))
      ).asc((u) => u.index);
      const subService = await loadServiceTypes();
      setData(
        ordenateData.map((item) => {
          const parsedData = JSON.parse(item.service_types);
          const subServiceFilter = parsedData.filter(item => { 
            const filteredSubService  = subService.filter(row => row.id === item.id && item.value);
            return filteredSubService.length > 0;
          })
          const checkService = subService.length !== subServiceFilter.length;
          return { ...item, checkService };
        })
      );
    }
  };

  const loadServiceTypes = async () => {
    let { data, error } = await supabase
      .from("ServiceTypes")
      .select("*")
      .eq("id_company", company.id)
      .is("deleted_at", null);
    if (!error) {
      return data.map((item) => ({ description: item.title, ...item }));
    }
  };

  const columns = [
    {
      id: 1,
      field: "additionalColumn",
      label: "Ordem",
      width: "100px",
      pinned: true,
      resizable: false,
      cellRenderer: ({ data }) => {
        return <div style={{ paddingLeft: "40px" }}>{data.index}</div>;
      },
    },
    {
      id: 2,
      field: "title",
      label: "Valor x Hora",
      width: "1fr",
      paddingLeft: "30px !important",
      resizable: false,
      cellRenderer: ({ data }) => {
        return (
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {data.checkService ? <IoWarning /> : null}
            <div>{data.title}</div>
          </div>
        );
      },
    },
    {
      id: 3,
      field: "actions",
      label: "Ações",
      pinned: true,
      resizable: false,
      width: "70px",
      cellRenderer: (row) => (
        <ActionCell
          data={{ ...row.data, index: row.data.index || row.rowIndex }}
        />
      ),
    },
  ];

  return <ValueHourChartScreen columns={columns} rows={data} />;
};
export default ValueHourChartContainer;

const ActionCell = ({ data }) => {
  let history = useHistory();
  return (
    <div className="field-actions">
      <BsPencilFill
        className="icon-action edit"
        onClick={() => history.push(`/bodyshop/details/${data.id}`, data)}
      />
    </div>
  );
};
