import React, { useState } from 'react';
import { DefaultButton } from '../Button/Button';
import { DateInput, DefaultInput, IconInput, LabelInput, LabelSelect } from '../Input/Input';
import { IconButton } from "../Button/Button";
import { AiOutlineCalendar, AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BsPencilFill, BsPercent, BsSearch, BsTrashFill } from 'react-icons/bs';
import { BiTrashAlt } from 'react-icons/bi';
import { CheckBox } from '../CheckBox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';


export const RouteModal = ({ expanded, children, }) => {
    const history = useHistory()
    const location = useLocation()
    const path = location.pathname.split('/')[1]
    const compareValues = useSelector(state => state.app[`compareValues_${path}`] || false)

    const closeModal = () => {
        if (compareValues) {
            Swal.fire({
                title: 'Deseja sair sem salvar?',
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                icon: 'warning',
                text: 'Ao sair, seus dados seram descartados.',
                denyButtonText: `Descartar`,
                cancelButtonText: 'Cancelar',

            }).then((result) => {
                if (result.isDenied) {
                    history.goBack();
                }
            })

        } else {
            history.goBack();
        }
        // history.goBack();
    }
    return (
        <Modal onRequestClose={closeModal}
            className={`prospera-modal ${expanded ? "expanded" : ""}`}
            isOpen={true}
            shouldReturnFocusAfterClose={false}
            onAfterOpen={() => document.getElementById('inputInsideModal').focus()}
        >
            <ModalBody className='modal-body' >
                {children}
            </ModalBody>
        </Modal>
    )
}

export const ModalNewService = ({ closeModal, title }) => {
    const subServices = [
        { type: 'Preparação', percentage: '40' },
        { type: 'Aplicação de Tinta', percentage: '25' },
        { type: 'Polimento Repintura', percentage: '15' },
        { type: 'Gasto Material', percentage: '20' }
    ]

    return (
        <div className='modal-container'>
            <div className='modal-header'>
                <div className='header-title'>
                    <h4 className='modal-title'>{title}</h4>
                    {/* <h6 className='text subtitle'>Lista de checklists</h6> */}
                </div>
                <AiOutlineClose color='white' className='close-Icon' onClick={closeModal} />
            </div>
            <div className='desc-input'>
                <h5 className='modal-title'>Adicionar novo sub serviço</h5>
                <div className='desc-line'>
                    <LabelInput placeholder={'Insira a descrição'} label={'Descrição'} />
                    <div className='input-size'>
                        <div className='col'>
                            <Label className='percent-label' >Percentual</Label>
                            <IconInput inputClassname='input-size' icon={<BsPercent className="search-Icon" />} />
                        </div>
                        <IconButton className='add-btn' icon={<AiOutlinePlus size={20} />} />
                    </div>
                </div>
            </div>
            <div className='sub-services'>
                <h5 className='modal-title'>Adicionar novo sub serviço</h5>
                {subServices.map((item) => {
                    return <div className='sub-services-render'>
                        <h6 className='sub-services-info'>{item.type}</h6>
                        <div className='input-size'>
                            <div className='col'>
                                <IconInput value={item.percentage} inputClassname='input-size' icon={<BsPercent className="search-Icon" />} />
                            </div>
                            <IconButton className='delete-btn-modal' icon={<BiTrashAlt size={20} />} />
                        </div>
                    </div>
                })}
            </div>
            <div className='modal-footer-table'>
                <DefaultButton
                    className="addnew-button cancel"
                    label={isMobile ? "" : "Cancelar"}
                    icon={<AiOutlineClose className='button-icon-cancel' />}
                    onClick={() => closeModal()}
                />
                <DefaultButton
                    className="addnew-button"
                    label={isMobile ? "" : "Salvar"}
                    icon={<AiOutlineCheck className='button-icon' />}
                />
            </div>
        </div>
    );
}

export const ModalSteps = ({ closeModal, title, subTitle }) => {
    const [checked, setChecked] = useState([])

    const onChecked = (item) => {
        const verify = checked.filter(row => row.id === item.id)
        if (verify && verify.length > 0) {
            setChecked(checked.filter(data => data.id !== item.id))
        } else {
            setChecked(checked.concat([item]))
        }
    }

    const checkItem = [
        { obs: 'Verificar order de serviço', id: 3 },
        { obs: 'Desengraxar as peças que serão reparadas', id: 1 },
        { obs: 'Enviar para a produção', id: 2 },
    ]

    return (
        <div style={{height:"auto !important"}} className='modal-container'>
            <div className='modal-header'>
                <div className='header-title'>
                    <h4 className='modal-title'>{title}</h4>
                </div>
                <AiOutlineClose color='white' className='close-Icon' onClick={closeModal} />
            </div>
            <div className='desc-input'>
                <h5 className='modal-title'>{subTitle}</h5>
                <div className='desc-line'>
                    <LabelInput className='input' classLabel='input-label' placeholder={'Insira o item'} label={'Novo item para a lista de conferência obrigatória'} />
                    <div className='input-size'>
                        <IconButton className='add-btn' icon={<AiOutlinePlus size={20} />} />
                    </div>
                </div>
            </div>
            <div className='sub-services'>
                <h5 className='modal-title'>Conferência Obrigatória</h5>
                {checkItem.map((item) =>
                    <div style={{ display: 'flex', flexDirection: 'row', aligItems: 'center', marginBottom: '15px' }}>
                        <CheckBox onChange={() => onChecked(item)} checked={checked && checked.find(row => row.id === item.id) && checked.find(row => row.id === item.id).id ? true : false} />
                        <h6 className='checklist-obs'>{item.obs}</h6>
                    </div>)}
            </div>
            <div className='modal-footer-table'>
                <DefaultButton
                    className="addnew-button cancel"
                    label={isMobile ? "" : "Cancelar"}
                    icon={<AiOutlineClose className='button-icon-cancel' />}
                    onClick={() => closeModal()}
                />
                <DefaultButton
                    className="addnew-button"
                    label={isMobile ? "" : "Salvar"}
                    icon={<AiOutlineCheck className='button-icon' />}
                />
            </div>
        </div>
    );
}