import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ModalBody } from "reactstrap";
import Modal from "react-modal";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ConsoleView, isMobile } from "react-device-detect";
import { saveVehicle } from "../../core/utils/vehicles";
import { alertError, alertSuccess } from "../../components/Toast";
import {
  LabelInput,
  LicensePlateInput,
  LabelCreatable,
  ChassiInput,
} from "../../components/Input/Input";
import { BsCardText, BsFillTrashFill, BsPerson } from "react-icons/bs";
import { loadClient } from "../../core/utils/client";
import { supabase } from "../../supabaseClient";

const BudgetAddVehicle = ({ setOpen, isOpen, setReload, defaultValues }) => {
  const [values, setValues] = useState({});
  const company = useSelector((state) => state.app.company || {});
  const [loadingPlate, setLoadingPlate] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  var containPlate = `${values?.plate}`.match(/[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}/);

  useEffect(() => {
    loadBrand();
  }, []);

  useEffect(() => {
    if (brandOptions.length > 0 && values?.brand && !values?.brand?.label) {
      const selectedBrand =
        brandOptions?.find((brand) => brand.nome === values?.brand) || {};
      setValues((old) => ({
        ...old,
        brand: {
          value: selectedBrand.codigo || old.brand,
          label: selectedBrand.nome || old.brand,
        },
      }));
    }
    if (values?.brand?.label && values?.model && !values?.model?.label) {
      const selectedModel =
        modelOptions?.find((model) => model.nome === values.model) || {};
      setValues((old) => ({
        ...old,
        model: {
          value: selectedModel?.codigo || old?.model,
          label: selectedModel?.nome || old?.model,
        },
      }));
    }
  }, [values, brandOptions, modelOptions]);

  useEffect(() => {
    const createdBrand = brandOptions.find(
      (item) => item.id === values?.brand?.value
    );
    if (!createdBrand) {
      loadModel();
    }
  }, [values?.brand?.value]);

  const createOption = async (name, field) => {
    setValues((values) => ({
      ...values,
      [field]: { label: name, value: "0" },
    }));
  };

  const loadBrand = async () => {
    const response = await fetch(
      "https://parallelum.com.br/fipe/api/v1/carros/marcas",
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => console.log("error", error));
    setBrandOptions(response);
  };

  const loadModel = async () => {
    const response = await fetch(
      `https://parallelum.com.br/fipe/api/v1/carros/marcas/${values?.brand?.value}/modelos`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => console.log("error", error));
    if (!response?.error) {
      setModelOptions(response.modelos);
    }
  };

  const closeModal = () => {
    setValues({});
    setOpen((open) => !open);
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    const existedPlate = await getExistedPlate(values.plate, company);
    try {
      const extraData = {};

      if (values && !values.brand) {
        alertError("Marca do veículo é obrigatória.");
        return;
      } else {
        if (values?.brand?.label) {
          extraData["brand"] = values?.brand?.label;
        }
      }

      if (values && !values.model) {
        alertError("Modelo do veículo é obrigatório.");
        return;
      } else {
        if (values?.model?.label) {
          extraData["model"] = values?.model?.label;
        }
      }

      if (values && !values.plate) {
        alertError("Placa do veículo é obrigatória.");
        return;
      }

      if (existedPlate?.length > 0) {
        alertError("Placa já cadastrada.");
        return;
      }

      const save = await saveVehicle(values.id, {
        responsible: defaultValues?.client?.value,
        company: company.id,
        ...values,
        ...extraData,
      });
      alertSuccess("Sucesso ao salvar o veículo!");
      setValues({});
      setOpen((open) => !open);
      setReload((reload) => !reload);
    } catch (error) {
      alertError("Erro ao salvar o veículo, tente novamente mais tarde.");
    }
  };

  const changePlate = (oldPlate) => {
    const plate = oldPlate.toUpperCase();
    onChange("plate", plate);
    if (
      plate &&
      plate.length > 6 &&
      /[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}/.test(plate)
    ) {
      setLoadingPlate(true);
      fetch(`https://cluster.apigratis.com/api/v2/vehicles/dados`, {
        method: "POST",
        body: JSON.stringify({ placa: plate.replace("-", "") }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_API_BRASIL}`,
          // SecretKey: "1a42e92c-8ba8-4a12-a99f-287ab4245ac2",
          // PublicToken: "a2743b52063cd87a65d1633f5c74f5",
          DeviceToken: process.env.REACT_APP_DEVICE_API_BRASIL,
        },
      })
        .then((response) => {
          response.json().then((data) => {
            if (data.response.message === `Veículo ${plate.replace("-", "")} não foi encontrado no banco de dados, nem na redundância!`) {
              alertError("Erro ao achar o veículo, por favor continue o cadastro manualmente.");
              setLoadingPlate(false);
              return;
            }
            const {
              MODELO,
              MARCA,
              ano,
              anoModelo,
              combustivel,
              cor,
              extra = {},
            } = data.response;
            const { chassi, motor } = extra;
            setValues((values) => ({
              ...values,
              plate,
              model: MODELO?.includes(MARCA)
                ? MODELO.replace(MARCA, "").replace("/", "")
                : MODELO,
              yearmodel: anoModelo,
              chassi: chassi,
              engine: motor,
              color: cor,
              fuel: combustivel,
              brand: MARCA,
              year: ano,
            }));
            setLoadingPlate(false);
          });
        })
        .catch((err) => {
          setLoadingPlate(false);
        });
    }
  };

  const getExistedPlate = async (plate, company) => {
    const client = await loadClient(company.id);
    let { data, error } = await supabase
      .from("Vehicle")
      .select("*")
      .in(
        "responsible",
        client.map((item) => item.id)
      )
      .eq("plate", plate)
      .is("deleted_at", null);
    return data;
  };

  return (
    <Modal
      onRequestClose={closeModal}
      className={"prospera-modal"}
      isOpen={isOpen}
    >
      <input style={{ visibility: "hidden", position: "absolute" }} />
      <ModalBody className="modal-body">
        <div className="modal-container">
          <div className="modal-header">
            <div className="header-title">
              <h4 className="modal-title">{"Adicionar Veículo"}</h4>
            </div>
            <div className="header-buttons">
              <DefaultButton
                className="modal-footer-button"
                label={isMobile ? "" : "Salvar"}
                icon={<AiOutlineCheck className="button-icon" />}
                onClick={onSave}
              />
              <DefaultButton
                className="modal-footer-button cancel"
                label={isMobile ? "" : "Cancelar"}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
          </div>
          <Tabs>
            <TabList>
              <Tab>
                <text> Dados </text>
                <BsPerson />
              </Tab>
              <Tab>
                <text> Observações </text>
                <BsCardText />
              </Tab>
            </TabList>
            <TabPanel>
              {isMobile ? (
                <div>
                  <div className="">
                    <LicensePlateInput
                      loadingPlate={loadingPlate}
                      value={values.plate}
                      onChange={(value) => changePlate(value)}
                      placeholder={"Insira a placa do veículo"}
                      label={"Placa"}
                    />
                  </div>
                  <div className="">
                    <LabelInput
                      value={values.color}
                      onChange={(value) => onChange("color", value)}
                      placeholder={"Insira a cor do veículo"}
                      label={"Cor do veículo"}
                    />
                    <LabelInput
                      value={values.fuel}
                      onChange={(value) => onChange("fuel", value)}
                      placeholder={"Insira o combustível do veículo"}
                      label={"Combustível"}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="main-upper-div width-correction">
                    <LicensePlateInput
                      loadingPlate={loadingPlate}
                      value={values.plate}
                      onChange={(value) => changePlate(value)}
                      placeholder={"Insira a placa do veículo"}
                      label={"Placa"}
                    />
                  </div>
                  <div className="main-upper-div width-correction">
                    <LabelInput
                      value={values.color}
                      onChange={(value) => onChange("color", value)}
                      placeholder={"Insira a cor do veículo"}
                      label={"Cor do veículo"}
                    />
                    <LabelInput
                      value={values.fuel}
                      onChange={(value) => onChange("fuel", value)}
                      placeholder={"Insira o combustível do veículo"}
                      label={"Combustível"}
                    />
                  </div>
                </div>
              )}
              {/* <LabelInput
                value={values.brand}
                onChange={(value) => onChange("brand", value)}
                placeholder={"Insira a marca do veículo"}
                label={"Marca"}
              /> */}
              <LabelCreatable
                onCreateOption={(name) => createOption(name, "brand")}
                options={brandOptions?.map((item) => ({
                  value: item.codigo,
                  label: item.nome,
                }))}
                className="generic-input select-creatable"
                value={
                  values.brand
                    ? [
                        brandOptions.find(
                          (item) =>
                            item.nome === values.brand ||
                            (item.nome &&
                              typeof item.nome === "string" &&
                              item.nome.includes(values.brand)) ||
                            (values.brand.includes &&
                              typeof values.brand.includes === "function" &&
                              values.brand.includes(item.nome))
                        ) || { label: values.brand, value: null },
                      ]
                    : values.brand
                }
                defaultValue={values.brand}
                onChange={(value) => onChange("brand", value)}
                placeholder={"INSIRA A MARCA DO VEÍCULO"}
                label={"Marca"}
                disabled={!containPlate}
              />
              <LabelCreatable
                disabled={!values.brand}
                onCreateOption={(name) => createOption(name, "model")}
                options={modelOptions?.map((item) => ({
                  value: item.codigo,
                  label: item.nome,
                }))}
                className="generic-input select-creatable"
                value={
                  values.model
                    ? [
                        modelOptions.find(
                          (item) =>
                            item.nome === values.model ||
                            (item.nome &&
                              typeof item.nome === "string" &&
                              item.nome.includes(values.model)) ||
                            (values.model.includes &&
                              typeof values.model.includes === "function" &&
                              values.model.includes(item.nome))
                        ) || { label: values.model, value: null },
                      ]
                    : values.model
                }
                defaultValue={values.model}
                onChange={(value) => onChange("model", value)}
                placeholder={"INSIRA O MODELO DO VEÍCULO"}
                label={"Modelo"}
              />
              <LabelInput
                value={values.year}
                onChange={(value) => onChange("year", value)}
                placeholder={"Insira o ano do veículo"}
                label={"Ano de fabricação"}
              />
              <LabelInput
                value={values.yearmodel}
                onChange={(value) => onChange("yearmodel", value)}
                placeholder={"Insira o ano do modelo do veículo"}
                label={"Ano do modelo"}
              />
              <div>
                <ChassiInput
                  value={values.chassi}
                  onChange={(value) => onChange("chassi", value)}
                  placeholder={"Insira o Chassi"}
                  label={"Chassi do veículo"}
                />
                <LabelInput
                  value={values.engine}
                  onChange={(value) => onChange("engine", value)}
                  placeholder={"Insira o motor"}
                  label={"Motor do veículo"}
                />
              </div>
            </TabPanel>

            <TabPanel>
              <LabelInput
                type={"textarea"}
                value={values.observation}
                onChange={(value) => onChange("observation", value)}
                placeholder={"Insira a observação"}
                inputClassname={"text-area-min"}
                label={"Observação"}
              />
            </TabPanel>
          </Tabs>

          {values && !values.id ? (
            <div />
          ) : (
            <DefaultButton
              className="modal-footer-button delete"
              label={"Excluir"}
              icon={<BsFillTrashFill className="button-icon-cancel" />}
              onClick={onRemove}
            />
          )}
          <div className="modal-footer-table"></div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BudgetAddVehicle;
