import React, { useEffect } from "react"
import { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BsPencilFill } from "react-icons/bs";
import ServiceAestheticScreen from "../../screens/ServiceAesthetic/ServiceAestheticScreen";
import { supabase } from "../../supabaseClient";
import { useSelector } from "react-redux";

const ServiceAestheticContainer = ({}) => {
    const [data, setData] = useState([])
    const company = useSelector(state => state.app.company || {})
    const reloadAeServices = useSelector(state => state.app.reloadAeServices || false)

    useEffect(() => {
        loadData()
    }, [company, reloadAeServices])
    

    const loadData = async () => {
        let { data, error } = await supabase
            .from('AeServices')
            .select('*')
            .eq("id_company", company.id)
            .is('deleted_at', null)
        if (!error) {
            setData(data)
        }
    }

    return (
        <ServiceAestheticScreen
            columns={columns}
            rows={data}
        />
    )
}

    export default ServiceAestheticContainer
    
    const columns = [
        {
            id: 1,
            field: 'icon',
            label: '',
            width: '50px',
            pinned: true,
            resizable: true,
            cellRenderer: ({ data }) => {
                return (
                    <div />
                );
            },
        },
        {
            id: 2,
            field: 'title',
            label: 'Serviços',
            width: '1fr',
            paddingLeft: '30px !important',
            resizable: false,
        },
        {
            id: 3,
            field: 'actions',
            label: 'Ações',
            pinned: true,
            resizable: false,
            width: '70px',
            cellRenderer: ({ data }) => <ActionCell data={data} />,
        },
    ];

    const ActionCell = ({ data }) => {
        let history = useHistory();
        return (
            <div className='field-actions'>
                <BsPencilFill className='icon-action edit' onClick={() => history.push(`/aesthetic/details/${data.id}`, data)} />
            </div>
        );
    };
