import { useState, useEffect, useMemo, useRef } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { DayInput, LabelInput } from "../../components/Input/Input";
import { DefaultButton } from "../../components/Button/Button";
import "react-tabs/style/react-tabs.css";
import GridTable from "@nadavshaar/react-grid-table";
import { isMobile } from "react-device-detect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";
import moment from "moment";
import ExternalBudgetPrint from "../../components/ExternalBudgetPrint/ExternalBudgetPrint";
// import { Helmet } from "react-helmet";

const ModalExternalBudget = ({
  values = {},
  client,
  vehicle,
  service,
  parts,
  loading,
  approve,
  analyse,
  inDoubt,
  printCompany,
}) => {
  const today = dayjs();

  const defaultDate = today.add(30, "day");

  const getTotalSum = (service, parts) => {
    let totalSum = 0;
    for (const item of service) {
      totalSum += parseFloat(item.value) || 0;
    }
    for (const item of parts) {
      totalSum += parseFloat(item.value) || 0;
    }

    return totalSum;
  };

  const totalSum = useMemo(() => getTotalSum(service, parts), [service, parts]);
  const totalServices = useMemo(() => getTotalSum(service, []), [service]);
  const totalParts = useMemo(() => getTotalSum([], parts), [parts]);
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <div className="imageExternal">
          <img
            className="backWorkerExternal"
            src={require("../../assets/images/worker.png").default}
          />
        </div>
      )}
      {/* <Helmet>
        <title>Prospera</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={window.location.pathname + window.location.search}
        />
        <meta property="og:title" content="Prospera" />
        <meta property="og:description" content="Prospera" />
        <meta
          property="og:image"
          content="../../assets/images/worker.png"
        />
      </Helmet> */}
      <div className="eb-container eb-container-print">
        <div className="form">
          <div className="modal-header">
            <div className="header-title">
              <h4 className="modal-title">{"ORÇAMENTO"}</h4>
            </div>
            <div className="header-buttons"></div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="budget-modal-main-div">
              <div className="size-dropdown">
                <div className="void-class">
                  <LabelInput
                    onChange={() => {}}
                    placeholder={"Escolha o Cliente"}
                    disabled={true}
                    label={"Cliente"}
                    // options={client}
                    value={
                      client.find(
                        (item) =>
                          item.value === values?.client?.value ||
                          item.value === values.client
                      ) &&
                      client.find(
                        (item) =>
                          item.value === values?.client?.value ||
                          item.value === values.client
                      ).value
                        ? client.find(
                            (item) =>
                              item.value === values?.client?.value ||
                              item.value === values.client
                          ).label
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="budget-modal-main-div">
              <div className="size-dropdown">
                <div className="void-class">
                  <LabelInput
                    onChange={() => {}}
                    placeholder={"Escolha o Veículo"}
                    label={"Veículo"}
                    // options={vehicle}
                    disabled={true}
                    value={
                      vehicle.find(
                        (item) =>
                          item.value === values?.vehicle?.value ||
                          item.value === values.vehicle
                      ) &&
                      vehicle.find(
                        (item) =>
                          item.value === values?.vehicle?.value ||
                          item.value === values.vehicle
                      ).value
                        ? vehicle.find(
                            (item) =>
                              item.value === values?.vehicle?.value ||
                              item.value === values.vehicle
                          ).label
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: "10px" }}></div>
          <div style={{}}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <text
                style={{
                  color: "white",
                  fontWeight: "500",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {" "}
                Serviços{" "}
              </text>
            </div>
            <div className="grid-table-wrapper">
              <div
                className="bodyshop-body modal-budget modal-budget-gridtable gridtable-for-service-budget"
                style={{ maxHeight: "fit-content" }}
              >
                <GridTable
                  columns={columnsService()}
                  rows={service}
                  enableColumnsReorder={false}
                  showSearch={false}
                  canReorder={false}
                  isPaginated={false}
                  showColumnVisibilityManager={false}
                  showRowsInformation={false}
                  texts={{ noResults: "Nenhum resultado foi encontrado" }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline",
                marginBottom: 20,
              }}
            >
              <text
                style={{
                  color: "white",
                  fontSize: "16px",
                  marginRight: 10,
                }}
              >
                {" "}
                Soma de serviços:{" "}
              </text>
              <span
                style={{
                  color: "#62C300",
                  fontWeight: "light",
                  fontSize: "16px",
                }}
              >
                {totalServices.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          </div>
          <div style={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <text
                style={{
                  color: "white",
                  fontWeight: "500",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {" "}
                Peças{" "}
              </text>
            </div>
            <div
              className="bodyshop-body modal-budget modal-budget-gridtable gridtable-for-piece-budget"
              style={{ maxHeight: "fit-content" }}
            >
              <GridTable
                columns={columnsPiece()}
                rows={parts}
                enableColumnsReorder={false}
                showSearch={false}
                canReorder={false}
                isPaginated={false}
                showColumnVisibilityManager={false}
                showRowsInformation={false}
                texts={{ noResults: "Nenhum resultado foi encontrado" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline",
                marginBottom: 20,
              }}
            >
              <text
                style={{
                  color: "white",
                  fontSize: "16px",
                  marginRight: 10,
                }}
              >
                {" "}
                Soma de peças:{" "}
              </text>
              <span
                style={{
                  color: "#62C300",
                  fontWeight: "light",
                  fontSize: "16px",
                }}
              >
                {totalParts.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          </div>
          <div
            className="header-title"
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 10,
              marginBottom: 20,
            }}
          >
            <h4 className="modal-title">
              Valor total:{" "}
              <span
                style={{
                  marginLeft: 10,
                  color: "#62C300",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {totalSum.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </h4>
          </div>
          <div>
            {values.payment ? (
              <div
                className="payment-dropdown"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <text
                  style={{
                    color: "white",
                    fontStyle: "normal",
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Condição de pagamento{" "}
                </text>
                <div className="void-class" style={{ marginTop: 8 }}>
                  <LabelInput
                    disabled={true}
                    placeholder="Condição de pagamento"
                    value={values?.payment?.label}
                    onChange={() => {}}
                    className="budget-details-margin"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ paddingBottom: "10px" }}>
              <LabelInput
                type={"textarea"}
                inputClassname={"text-observation-min"}
                label={"Observações"}
                placeholder={"Insira a observação para o orçamento"}
                onChange={(value) => {}}
                disabled={true}
                value={values.observation}
                className="external-observation-input"
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: 3,
                alignItems: "center",
                gap: "30px",
              }}
            >
              {values.expiration ? (
                <div
                  style={{ width: "100%" }}
                  className="external-budget-inputs"
                >
                  <div style={{ marginBottom: 8 }}>
                    <text
                      style={{
                        color: "white",
                        textAlign: "end",
                        fontFamily: "poppins",
                        fontWeight: "500",
                      }}
                    >
                      Data de Validade
                    </text>
                  </div>
                  <LabelInput
                    disabled={true}
                    placeholder="Data de Validade"
                    value={moment(values.shareDate)
                      .add(values.expiration, "days")
                      .format("DD/MM/YYYY")}
                    onChange={() => {}}
                  />
                </div>
              ) : (
                <></>
              )}
              {values.deadline ? (
                <div
                  style={{ width: "100%" }}
                  className="external-budget-inputs"
                >
                  <div style={{ marginBottom: 8 }}>
                    <text
                      style={{
                        color: "white",
                        textAlign: "end",
                        fontFamily: "poppins",
                        fontWeight: "500",
                      }}
                    >
                      Data de Entrega
                    </text>
                  </div>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                    localeText={
                      ptBR.components.MuiLocalizationProvider.defaultProps
                        .localeText
                    }
                  >
                    <DayInput disabled={true} value={values.deadline} />
                  </LocalizationProvider>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {isMobile ? (
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {values && values.status === 3 ? (
                <h4 className="modal-title" style={{ color: "#62C300" }}>
                  Orçamento aprovado
                </h4>
              ) : // Verifica se a data de expiração é menor ou igual ao dia de hoje
              moment(
                  moment(values.shareDate).add(values.expiration, "days")
                ).isSameOrBefore(values.shareDate) ? (
                // Se expirou, exibe a mensagem de orçamento expirado em vermelho
                <h4 className="modal-title" style={{ color: "red" }}>
                  Orçamento expirado
                </h4>
              ) : (
                // Caso contrário, exibe o botão para aprovar o orçamento
                <div style={{ gap: 10, display: "flex", flexWrap: "wrap" }}>
                  <DefaultButton
                    className="modal-footer-button approve-btn btn-no-print"
                    label={"Aprovar orçamento"}
                    onClick={approve}
                    disabled={loading}
                  />
                  <DefaultButton
                    className="modal-footer-button pdf-btn btn-no-print"
                    disabled={true}
                    label={"Encaminhar"}
                  />
                  <DefaultButton
                    className="modal-footer-button pdf-btn btn-no-print"
                    onClick={handlePrint}
                    label={"Gerar documento (PDF)"}
                  />
                  <DefaultButton
                    className="modal-footer-button doubt-btn btn-no-print"
                    onClick={inDoubt}
                    label={"Estou com dúvidas e preciso de mais informações"}
                  />
                  <DefaultButton
                    className="modal-footer-button analyse-btn btn-no-print"
                    onClick={analyse}
                    label={"Vou analisar, depois retomamos contato"}
                  />
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {values && values.status === 3 ? (
                <h4 className="modal-title" style={{ color: "#62C300" }}>
                  Orçamento aprovado
                </h4>
              ) : // Verifica se a data de expiração é menor ou igual ao dia de hoje
              moment(
                  moment(values.shareDate).add(values.expiration, "days")
                ).isSameOrBefore(values.shareDate) ? (
                // Se expirou, exibe a mensagem de orçamento expirado em vermelho
                <h4 className="modal-title" style={{ color: "red" }}>
                  Orçamento expirado
                </h4>
              ) : (
                // Caso contrário, exibe o botão para aprovar o orçamento
                <div>
                  <DefaultButton
                    className="modal-footer-button approve-btn btn-no-print"
                    label={"Aprovar orçamento"}
                    onClick={approve}
                    disabled={loading}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <DefaultButton
                      className="modal-footer-button share-btn btn-no-print"
                      disabled={true}
                      label={"Encaminhar"}
                    />
                    <DefaultButton
                      className="modal-footer-button pdf-btn btn-no-print"
                      onClick={handlePrint}
                      label={"Gerar documento (PDF)"}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <DefaultButton
                      className="modal-footer-button doubt-btn btn-no-print"
                      onClick={inDoubt}
                      label={"Estou com dúvidas e preciso de mais informações"}
                    />
                    <DefaultButton
                      className="modal-footer-button analyse-btn btn-no-print"
                      onClick={analyse}
                      label={"Vou analisar, depois retomamos contato"}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ExternalBudgetPrint
        totalServices={totalServices}
        totalParts={totalParts}
        totalSum={totalSum}
        values={values}
        client={client}
        service={service}
        parts={parts}
        company={printCompany}
        ref={ref}
      />
    </>
  );
};

export default ModalExternalBudget;

export const sizeOptions = [
  // { id: 1, value: "PP", label: "PP" },
  { id: 2, value: "P", label: "P" },
  { id: 3, value: "M", label: "M" },
  { id: 4, value: "G", label: "G" },
  // { id: 5, value: "GG", label: "GG" },
];

export const paymentOptions = [
  { id: 1, value: "Crédito", label: "Crédito" },
  { id: 2, value: "Débito", label: "Débito" },
  { id: 3, value: "Dinheiro", label: "Dinheiro" },
  { id: 4, value: "Pix", label: "Pix" },
];

const columnsService = () => [
  {
    id: 1,
    field: "title",
    label: "Serviço",
    width: "1fr",
    className: "field-description",
    resizable: false,
    cellRenderer: ({ data }) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
          <div style={{ paddingLeft: "10px" }}>{data.title}</div>
          <div
            style={{
              gap: "5px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="field-actions-budget externalBudget-service-hour">
              {data.time}
            </div>
            <div className="field-actions-budget externalBudget-service-unit">
              {parseFloat(data.unitaryValue || 0).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
            <div className="field-actions-budget externalBudget-service-total">
              {data?.value === "NaN"
                ? "R$ 0,00"
                : parseFloat(data?.value || 0).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
            </div>
          </div>
        </div>
      );
    },
  },
];

const columnsPiece = () => [
  {
    id: 1,
    field: "name",
    label: "Peça",
    width: "1fr",
    className: "field-description",
    resizable: false,
    cellRenderer: ({ data }) => (
      <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
        <div style={{ paddingLeft: "10px" }}>{data.name}</div>
        <div
          style={{
            gap: "5px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="field-actions-budget externalBudget-piece-quantity">
            {data.quantity}
          </div>
          <div className="field-actions-budget externalBudget-piece-unit">
            {(data?.value && data?.quantity
              ? parseFloat(data.value) / data.quantity
              : data.unitaryValue
            ).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </div>
          <div className="field-actions-budget externalBudget-piece-total">
            {parseFloat(data.value || 0).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </div>
        </div>
      </div>
    ),
  },
];
