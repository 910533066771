import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setData } from "../../core/redux/app/Actions";

const ModulesScreen = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showDropdownItems = (id, field) => {
    dispatch(setData({ [field]: id }));
  };

  return (
    <div className="dashboard-placement">
      <div className="dashboard-home">
        <div>
          <img
            className="dashboard-logo"
            src={require("../../assets/images/web.png").default}
          />
        </div>
        <div className="dashboard-button-placement">
          <div
            className="location-button"
            onClick={() => {
              history.push(`/bodyshop`);
              showDropdownItems(1, "bodyShop");
              dispatch(setData({ navigate: `/bodyshop` }));
            }}
          >
            <text>Tipos de serviço</text>
          </div>
          <div
            className="location-button"
            onClick={() => {
              history.push(`/bodyshop`);
              showDropdownItems(2, "bodyShop");
              dispatch(setData({ navigate: `/bodyshop` }));
            }}
          >
            <text>Etapas do processo</text>
          </div>
          <div
            className="location-button"
            onClick={() => {
              history.push(`/bodyshop`);
              showDropdownItems(3, "bodyShop");
              dispatch(setData({ navigate: `/bodyshop` }));
            }}
          >
            <text>Valor x Hora</text>
          </div>
        </div>
        <div className="dashboard-button-placement">
          <div
            className="location-button"
            onClick={() => {
              history.push(`/bodyshop`);
              showDropdownItems(4, "bodyShop");
              dispatch(setData({ navigate: `/bodyshop` }));
            }}
          >
            <text>Serviços</text>
          </div>
          <div
            className="location-button"
            onClick={() => {
              history.push(`/parts`);
              dispatch(setData({ navigate: `/parts` }));
            }}
          >
            <text>Peças</text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulesScreen;
