import { useSelector } from 'react-redux';
import ModalBodyShop from '../../screens/ModalBodyShop/ModalBodyShop';

const ModalBodyShopContainer = (props) => {
    const bodyShop = useSelector(state => state.app.bodyShop || 1)

    return (
        <ModalBodyShop
            bodyShop={bodyShop}
        />
    )
}

export default ModalBodyShopContainer
