import React from "react";
import { BsHouseDoor } from "react-icons/bs";
import DashboardContainer from "../../containers/Dashboard/DashboardContainer";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RightNav from "../../containers/RightNav/RightNav";
import { DefaultButton } from "../Button/Button";
import { setData } from "../../core/redux/app/Actions";
import { useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

const menuRoutes = () => {
  return [
    {
      path: "/home",
      title: "Início",
      icon: <BsHouseDoor className="small" />,
      mobile: true,
      Component: DashboardContainer,
      details: (props) => <DashboardContainer {...props} />,
    },
  ];
};

const HomeButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = menuRoutes();
  return (
    <>
      {routes.map((route, index) => (
        <DefaultButton
          className="btn-cancel cancel new-table-button"
          label={"Fechar"}
          icon={<AiOutlineClose className="button-icon" />}
          key={index}
          onClick={(e) => {
            dispatch(setData({ navigate: route.path }));
            history.push(route.path);
          }}
        />
      ))}
    </>
  );
};

export default HomeButton;
