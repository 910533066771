import GridTable from "@nadavshaar/react-grid-table";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { inPlaceSort } from "fast-sort";
import "dayjs/locale/pt-br";
import CustomHeader from "../../components/GridtableHeader/Header";
import { statusBadges, statusOptions } from "../ModalBudget/ModalBudget";
import { isMobile } from "react-device-detect";

const BudgetScreen = ({ data, user, onRemove, filter, setFilter }) => {
  let history = useHistory();

  inPlaceSort(data).by("code");
  data.reverse();

  return (
    <div className="bodyshop-container">
      <div
        style={{ flexDirection: "column" }}
        className="bodyshop-body default-table budget-gridtable"
      >
        <CustomHeader filter={filter} setFilter={setFilter} />
        <GridTable
          columns={
            isMobile
              ? columns(onRemove, user, history).filter(
                  (item) => item.id !== 4 && item.id !== 5 && item.id !== 8
                )
              : columns(onRemove, user, history)
          }
          rows={data}
          showSearch={false}
          enableColumnsReorder={false}
          canReorder={false}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{
            noResults: "Nenhum resultado foi encontrado",
            totalRows: "Número de itens:",
            rows: "Itens:",
            selected: "Selecionado",
            rowsPerPage: "Itens por página:",
            page: "Página:",
            of: "de",
            prev: "Anterior",
            next: "Próximo",
            columnVisibility: "Colunas visíveis",
          }}
        />
      </div>
    </div>
  );
};

export default BudgetScreen;

const columns = (onRemove, user, history) => {
  return [
    {
      id: 1,
      field: "id",
      label: isMobile ? "Cód e Cliente" : "Cód",
      width: "1fr",
      resizable: false,
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name mobile-clickable-name"
          onClick={() => history.push(`/budgets/details/${data.id}`, data)}
        >
          {!isMobile ? data.code : ""}
          {isMobile ? <span>{data.code}</span> : ""}
          {isMobile ? <br /> : <></>}
          {isMobile ? <span>{data.client_name}</span> : ""}
        </div>
      ),
    },
    {
      id: 3,
      field: "client_name",
      label: isMobile ? "Veículo" : "Cliente",
      resizable: false,
      width: "auto",
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name mobile-clickable-name"
          onClick={() => history.push(`/budgets/details/${data.id}`, data)}
        >
          {!isMobile ? data.client_name : ""}
          {isMobile ? <span>{data.Vehicle.model}</span> : ""}{" "}
          {isMobile ? <br /> : <></>}
          {isMobile ? <span>{data.Vehicle.plate}</span> : ""}
        </div>
      ),
    },
    {
      id: 4,
      field: "vehicle_model",
      label: "Carro",
      resizable: false,
      width: "auto",
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name mobile-clickable-name"
          onClick={() => history.push(`/budgets/details/${data.id}`, data)}
        >
          {data.Vehicle.model} <br />
        </div>
      ),
    },
    {
      id: 5,
      field: "vehicle_name",
      label: "Placa",
      resizable: false,
      width: "auto",
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name mobile-clickable-name"
          onClick={() => history.push(`/budgets/details/${data.id}`, data)}
        >
          {data.Vehicle.plate}
        </div>
      ),
    },
    {
      id: 6,
      field: "status",
      label: "Status",
      resizable: false,
      width: "auto",
      cellRenderer: ({ data }) => {
        const selectedStatus = statusOptions.find(
          (item) => item.id === data.status
        );
        let icon, text, color, backgroundColor;
        switch (data.status) {
          case 1:
            // icon = <FaXmark />;
            backgroundColor = "#c95555";
            break;
          case 2:
            backgroundColor = "#b5b5b5";
            break;
          case 3:
            // icon = <FaCheck />;
            backgroundColor = "#00A86B";
            break;
          case 4:
            backgroundColor = "#b36a22";
            break;
          case 5:
            backgroundColor = "#6eb6ff";
            break;
          case 6:
            // icon = <FaQuestion />;
            backgroundColor = "#eead2d ";
            break;
          default:
            text = null;
            color = "#000000";
            backgroundColor = "#FFFFFF";
        }
        const selectedBadge = statusBadges.find(
          (item) => item.id === data.status_client
        );
        let colorDot;
        switch (data.status_client) {
          case 3:
            // icon = <FaCheck />;
            colorDot = "#00915c";
            break;
          case 5:
            // icon = <FaMagnifyingGlass />;
            colorDot = "#007fff";
            break;
          case 6:
            // icon = <FaQuestion />;
            colorDot = "#ffa900 ";
            break;
        }
        return (
          <div>
            <div
              // className="clickable-name colorful-status"
              className="clickable-name mobile-clickable-name color-name"
              style={{
                color: color,
                backgroundColor: backgroundColor,
                // display: "flex",
                // alignItems: "center",
                // gap: "5px",
              }}
              onClick={() => history.push(`/budgets/details/${data.id}`, data)}
            >
              {selectedBadge ? (
                <div
                  style={{
                    color: colorDot,
                    backgroundColor: colorDot,
                    borderRadius: "100%",
                    height: "15px",
                    width: "15px",
                  }}
                  onClick={() =>
                    history.push(`/budgets/details/${data.id}`, data)
                  }
                />
              ) : (
                ""
              )}
              {/* {icon} */}
              <text>{selectedStatus ? selectedStatus.value : ""}</text>
            </div>
          </div>
        );
      },
    },
    // {
    //   id: 7,
    //   field: "status_client",
    //   label: "Status",
    //   resizable: false,
    //   width: "auto",
    //   cellRenderer: ({ data }) => {
    //     const selectedBadge = statusBadges.find(
    //       (item) => item.id === data.status_client
    //     );
    //     let icon, text, color, backgroundColor;
    //     switch (data.status_client) {
    //       case 3:
    //         // icon = <FaCheck />;
    //         backgroundColor = "#00A86B";
    //         break;
    //       case 5:
    //         // icon = <FaMagnifyingGlass />;
    //         backgroundColor = "#6eb6ff";
    //         break;
    //       case 6:
    //         // icon = <FaQuestion />;
    //         backgroundColor = "#eead2d ";
    //         break;
    //       default:
    //         text = null;
    //         color = "#00000000";
    //         backgroundColor = "#00000000";
    //     }

    //     return (
    //       <div
    //         // className="clickable-name colorful-status-icon"
    //         className="clickable-name colorful-status-icon mobile-clickable-name"
    //         style={{
    //           color: color,
    //           backgroundColor: backgroundColor,
    //           borderRadius: "5px",
    //           width: "fit-content",
    //           // height: "31px",
    //           // paddingRight: "10px",
    //           // paddingLeft: "10px",
    //           // paddingTop: "3px",
    //           // marginLeft: "25px",
    //         }}
    //         onClick={() => history.push(`/budgets/details/${data.id}`, data)}
    //       >
    //         {icon}
    //         {selectedBadge ? selectedBadge.value : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      id: 8,
      field: "budget_actions",
      label: "Ações",
      resizable: false,
      width: "70px",
      cellRenderer: ({ data }) => {
        if (!isMobile) {
          return (
            <ActionCell
              data={data}
              onRemove={onRemove}
              user={user}
              history={history}
            />
          );
        }
      },
    },
  ];
};

const ActionCell = ({ data, history }) => {
  return (
    <div className="field-actions">
      <BsPencilFill
        className="icon-action edit"
        onClick={() => history.push(`/budgets/details/${data.id}`, data)}
      />
    </div>
  );
};
