import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export const DefaultButton = ({
  label,
  disabled,
  onClick,
  className = "",
  icon,
  id = "",
}) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      disabled={disabled}
      className={`button ${className}`}
      id={id}
    >
      {icon}
      {label ? <span>{label}</span> : <></>}
    </Button>
  );
};

export const LinkButton = ({ label, disabled, onClick, className = "" }) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      disabled={disabled}
      className={`link-button ${className}`}
    >
      <span>{label}</span>
    </Button>
  );
};

export const NavLink = ({ to, label, className }) => {
  return (
    <Link to={to} className={`link-button ${className}`}>
      <span>{label}</span>
    </Link>
  );
};

export const IconButton = ({ icon, className, onClick, ...props }) => {
  return (
    <Button
      className={`link-button ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      color="link"
      {...props}
    >
      {icon}
    </Button>
  );
};
