import React, { useState } from "react";
import ProductionPhasesAestheticContainer from "../../containers/ProductionPhaseAesthetic/ProductioPhaseAestheticContainer";
import ServiceAestheticContainer from "../../containers/ServiceAestheticContainer/ServiceAestheticContainer";

const AestheticScreen = ({ aesthetic }) => {
  return (
    <div className="bodyshop-container">
      <div className="bodyshop-body default-table-bodyshop">
        {aesthetic === 1 && <ProductionPhasesAestheticContainer />}
        {aesthetic === 2 && <ServiceAestheticContainer />}
      </div>
    </div>
  );
};

export default AestheticScreen;
